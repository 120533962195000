import React from "react";
import {Button} from "@mui/material";
import '../style/auth.css';


class ServerErrorPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            login: '',
            password: '',
            snackbarOpen: false
        }

        this.reload = () => {
            window.location.reload()
        }
    }

    render() {
        return <div className="dashboard-auth-container">
            <div className="dashboard-auth-container-entry">
                <h2 className="dashboard-auth-label">
                    Ошибка
                </h2>
                <div className="dashboard-auth-field">
                    Попробуйте позже
                </div>
                <div className="dashboard-auth-button">
                    <Button variant="outlined" size="medium"
                            onClick={this.reload}>
                        Перезагрузить
                    </Button>
                </div>
            </div>
        </div>
    }
}

export default ServerErrorPage;
