import React from 'react';
import './banner-item-table.component.css';
import {SERVER_URL} from "../../../config";

class BannerItemTable extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let customHeight = window.screen.width * window.devicePixelRatio > 1280
            ? window.screen.width * window.devicePixelRatio > 3839
                ? 108
                : 56
            : 36.8
        customHeight += this.props.banner.size * 3

        if (this.props.banner.type === 'image') {
            return <div className="banner-class-for-gap">
                <div className="banner-item-table-image-container" style={{
                    height: this.props.banner.size * customHeight,
                    maxHeight: this.props.banner.size * customHeight,
                    minHeight: this.props.banner.size * customHeight,
                }}>
                    <div className="banner-item-table-image">
                        <img alt="banner" src={SERVER_URL + this.props.banner.image.url.split('?')[0]}/>
                    </div>
                </div>
                <div className="divider-for-gap-3px-vertical"></div>
            </div>
        }

        return <div className="banner-class-for-gap">
            <div className="banner-item-table-text-container" style={{
                height: this.props.banner.size * customHeight,
                maxHeight: this.props.banner.size * customHeight,
                minHeight: this.props.banner.size * customHeight,
                backgroundColor: this.props.banner.background_color,
                color: this.props.banner.text_color
            }}>
                <div className="banner-item-table-text">
                    {this.props.banner.text}
                </div>
            </div>
            <div className="divider-for-gap-3px-vertical"></div>
        </div>
    }
}

export default BannerItemTable;
