import React from 'react';
import '../style/connectPage.css';
import QRCode from 'qrcode.react';
import {ClientJS} from 'clientjs';
import API from '../api';
import {getFingerprint} from '@thumbmarkjs/thumbmarkjs';
import {Fingerprint} from '../utils/storage';
import {ReactComponent as LogoIcon} from '../images/logo.svg';

class ConnectPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      timerId: null,
      fingerprint: null,
      loading: true,
    };

    this.client = new ClientJS();
    let fingerprint = this.client.getFingerprint();
    this.deviceId = Fingerprint.deviceId(fingerprint);

    this.checkUpdates = () => {
      API.updateDeviceConnection(this.deviceId).then((data) => {
        try {
          let url = `/store/window/${data.device.screen_number}/channel/${data.device.store.web_key}/`;
          window.location.href = url;
        } catch (e) {}
        this.setState({loading: false})
      });
    };

    this.startInterval = () => {
      if (this.state.timerId === null) {
        let timerId = setInterval(this.checkUpdates, 5000);
        this.setState({timerId: timerId});
      }
    };

    this.genDeviceLabel = () => {
      const fingerprint = this.client.getFingerprint();
      const browser = this.client.getBrowser();
      const browserVersion = this.client.getBrowserVersion();
      const os = this.client.getOS();
      const osVersion = this.client.getOSVersion();
      const screenPrint = this.client.getScreenPrint();

      return `${browser}_${browserVersion}_/_${os}_${osVersion}_/_${screenPrint}_/_${fingerprint}`;
    };

    this.deviceLabel = this.genDeviceLabel();
  }

  componentDidMount() {
    getFingerprint().then((fp) => {
      this.setState({fingerprint: fp}, () => {
        this.checkUpdates();
        this.startInterval();
      });
    });
  }

  render() {
    if (this.state.loading) return
    let url = `${window.location.origin}/connect/device/?fingerprint=${this.deviceId}&device_label=${this.deviceLabel}`;
    return (
      <div className='connect-page-container'>
        <div className='content-page-header'>
          <a className='HeaderMenu-logo' href='/'>
            <LogoIcon />
          </a>
        </div>

        <div className='connect-page-wrapper'>
          <div className='connect-page-title'>Привязка&nbsp;устройства</div>

          <div className='qr-info-wrapper'>
            <a href={url}><QRCode value={url} renderAs='canvas' size={256} level='L' /></a>
            <div className='connect-page-message'>
              Отсканируйте QR-код и&nbsp;войдите через браузер на&nbsp;телефоне
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ConnectPage;
