import React, {useState} from 'react';
import classes from './beer-ended-dialog.module.css';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import {Dialog, IconButton} from '@mui/material';
import Utils from '../../utils';

function BeerEndedDialogComponent({onClose, open, owner, onSubmit}) {
  const close = () => {
    setSelectCrane(null);
    onClose();
  };

  const [selectedCrane, setSelectCrane] = useState(null);

  return (
    <Dialog
      maxWidth={'sm'}
      fullWidth={false}
      scroll={Utils.isDeviceMobile() ? 'paper' : 'body'}
      classes={{root: 'modal-styles-root'}}
      onClose={close}
      open={open}
    >
      {owner ? (
        <div className={classes.modal}>
          <div className={classes.modalHeader}>
            <div className={classes.modalHeaderTitle}>
              <div className={classes.modalTitle}>Пиво закончилось!</div>
              <IconButton onClick={() => close()}>
                <CloseRoundedIcon />
              </IconButton>
            </div>
          </div>

          <div className={classes.descriptionText}>
            <div className={classes.descriptionTextTitle}>
              Если на&nbsp;кране закончилось пиво, а&nbsp;кег ещё не <br />
              поменяли, стоит выключить его на&nbsp;телевизорах
            </div>

            <div className={classes.descriptionTextInactive}>
              <div className={classes.buttonExampleInactive}>№</div>
              <div className={classes.exampleInactiveDescription}>
                Выбери кран, на&nbsp;котором <br /> закончилось пиво, чтобы
                выключить
              </div>
            </div>

            <div className={classes.descriptionTextActive}>
              <div className={classes.buttonExampleActive}>
                <span>№</span>
                <span className={classes.vikl}>выкл</span>
              </div>
              <div className={classes.exampleActiveDescription}>
                Такой кран уже выключен <br />
                Выбери его, чтобы включить
              </div>
            </div>
          </div>

          <div className={classes.controlsDivider}></div>

          <div className={classes.buttonComposition}>
            {owner.cranes.map((crane) =>
              crane.number === selectedCrane ? (
                <button
                  onClick={() => setSelectCrane(null)}
                  key={crane.id}
                  className={classes.craneActionActive}
                >
                  <span className={classes.craneNumberAcitve}>
                    {crane.number}
                  </span>
                  {crane.is_product_end ? (
                    <span className={classes.viklActive}>выкл</span>
                  ) : null}
                </button>
              ) : (
                <button
                  onClick={() => setSelectCrane(crane.number)}
                  key={crane.id}
                  className={classes.craneAction}
                >
                  <span className={classes.craneNumber}>{crane.number}</span>
                  {crane.is_product_end ? (
                    <span className={classes.vikl}>выкл</span>
                  ) : null}
                </button>
              )
            )}
          </div>

          <div className={classes.controls}>
            <div className={classes.controlsDivider}></div>
            <div className={classes.controlsActionsWrapper}>
              <button
                className={classes.actionSubmit}
                disabled={selectedCrane === null}
                onClick={() => {
                  setSelectCrane(null);
                  onSubmit({store_id: owner.store.id, number: selectedCrane});
                }}
              >
                Применить
              </button>
              <button className={classes.actionCancel} onClick={() => close()}>
                Отмена
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </Dialog>
  );
}

export default BeerEndedDialogComponent;
