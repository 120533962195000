import React from 'react';
import './aside-cards-wrapper.component.css';
import AsideCard from './components/aside-card/aside-card.component';

function AsideCardsWrapperComponent({items}) {
  return (
    <div className='cards-composition'>
      {items.map((item) => {
        const {menuItem, crane} = item;

        return (
          <div key={menuItem.id} className='class-for-gap'>
            <AsideCard menuItem={menuItem} crane={crane} />
            <div className='divider-for-gap-12px-vertical'></div>
          </div>
        );
      })}
    </div>
  );
}

export default AsideCardsWrapperComponent;
