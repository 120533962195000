import React from 'react';
import '../style/connectDevicePage.css';
import API from '../api';
import {FormControl, InputLabel, MenuItem, Select} from '@mui/material';
import MultiRadioButton from './components/multiRadioButton';
import Button from '@mui/material/Button';
import {safetyInput} from './utils';
import {ReactComponent as LogoIcon} from '../images/logo.svg';
import SelectStore from './components/store-select/select-store.component';

// 2330102255

class ConnectDevicePage extends React.Component {
  constructor(props) {
    super(props);

    this.getFromQuery = (key) => {
      let search = window.location.search;
      let params = new URLSearchParams(search);
      return params.get(key);
    };

    this.state = {
      stores: [],

      fingerprint: this.getFromQuery('fingerprint'),
      deviceLabel: this.getFromQuery('device_label'),

      screenNumberValues: {1: '1', 2: '2', 3: '3', 4: '4', 5: '5', 6: '6'},
      screenNumber: 1,

      storeId: null,
    };

    this.deviceRegister = () => {
      let payload = {
        fingerprint: this.state.fingerprint,
        storeId: this.state.storeId,
        screenNumber: this.state.screenNumber,
        deviceLabel: this.state.deviceLabel,
      };
      API.connectDeviceRegister(payload).then((data) => {
        window.location.href = '/';
      });
    };

    this.setStoreId = (storeId) => this.setState({storeId: storeId});
  }

  componentDidMount() {
    API.profileStores().then((data) => {
      this.setState({stores: data.stores});
    });
  }

  render() {
    return (
      <div className='connect-device-page-container'>
        <div className='content-page-header'>
          <a className='HeaderMenu-logo' href='/cabinet'>
            <LogoIcon />
          </a>
        </div>

        <div className='connect-device-page-wrapper'>
          <div className='connect-device-page-title'>
            Привязка&nbsp;устройства
          </div>
          <div className='connect-device-page-message'>
            <span>{this.state.deviceLabel}</span>
          </div>

          <SelectStore
            stores={this.state.stores}
            label={'Выберите магазин'}
            sublabel={
              'Отметьте точку, на которой расположено привязываемое устройство'
            }
            setSelectedStore={this.setStoreId}
            selectedStoreId={this.state.storeId}
          />

          <MultiRadioButton
            title='Номер экрана'
            values={this.state.screenNumberValues}
            selected={this.state.screenNumber}
            selectValue={(value) => this.setState({screenNumber: value})}
          />

          <Button
            classes={{root: 'connect-device-page-button-save'}}
            component='label'
            role={undefined}
            variant='contained'
            tabIndex={-1}
            onClick={() => this.deviceRegister()}
          >
            Привязать
          </Button>
        </div>
      </div>
    );
  }
}

export default ConnectDevicePage;
