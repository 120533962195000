import React from "react";
import "./account-info.component.css";
import { ReactComponent as TelegramIcon } from "../../../../../images/telegram.svg";
import Storage from "../../../../../utils/storage";

function AccountInfoComponent() {
  const { email } = Storage.get("user");
  const { address } = Storage.get("store");

  return (
    <div className="card-wrapper">
      <div className="card-name">Данные аккаунта</div>
      <div className="card-part">
        <div className="part-name">Почта</div>
        <div className="part-value">{email}</div>
      </div>
      <div className="card-part">
        <div className="part-name">Доступные точки</div>
        <div className="part-value">{address}</div>
      </div>
      <div className="mail-edit-wrapper">
        <a className="mail-edit" href="https://t.me/blackllyy" target="_blank">
          <TelegramIcon />
          <div className="part-value">Хотите поменять почту?</div>
        </a>
      </div>
    </div>
  );
}

export default AccountInfoComponent;
