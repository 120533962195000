import React from "react";
import './multiRadioColorsButton.css'


function MultiRadioColorsButton({title, values, selectedIndex, selectValue, description}) {
    let classList = ['MultiRadioColorsButton-elements']
    if (Object.keys(values).length > 3) classList.push('MultiRadioColorsButton-elements-many')
    classList = classList.join(' ')

    return <div className="MultiRadioColorsButton-container">
        <div className="MultiRadioColorsButton-title">{title}</div>
        <div className={classList}>
            {values.map((value, index) => {
                let classList = ["MultiRadioColorsButton-element"]
                if (String(selectedIndex) === String(index)) classList.push('MultiRadioColorsButton-element-selected')

                return <div key={index} className={classList.join(' ')}
                            style={{background: value.bgColor, color: value.textColor}}
                            onClick={() => selectValue(index)}>
                    Аа
                </div>
            })}
        </div>
        {description ? <div className="MultiRadioColorsButton-description">{description}</div> : null}
    </div>
}

export default MultiRadioColorsButton;