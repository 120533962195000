import React from "react";
import '../style/adminPanel.css';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import {Avatar, Button, FormControlLabel, IconButton, Switch} from "@mui/material";
import API from "../api";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";

function generateSwitch(label, value, setNewValue) {
    return <FormControlLabel
        control={<Switch value={value} onClick={setNewValue}/>}
        label={label}/>
}

function renderMenu(setMenuStatus) {
    return <Box sx={{width: 250}} role="presentation" onClick={setMenuStatus(false)}>
        <List>
            {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
                <ListItem key={text} disablePadding>
                    <ListItemButton>
                        <ListItemIcon>
                            {index % 2 === 0 ? <InboxIcon/> : <MailIcon/>}
                        </ListItemIcon>
                        <ListItemText primary={text}/>
                    </ListItemButton>
                </ListItem>
            ))}
        </List>
        <Divider/>
        <List>
            {['All mail', 'Trash', 'Spam'].map((text, index) => (
                <ListItem key={text} disablePadding>
                    <ListItemButton>
                        <ListItemIcon>
                            {index % 2 === 0 ? <InboxIcon/> : <MailIcon/>}
                        </ListItemIcon>
                        <ListItemText primary={text}/>
                    </ListItemButton>
                </ListItem>
            ))}
        </List>
    </Box>
}

function renderObject(index, model, showColumns, openModel, addModel) {
    return <div key={index} className="admin-dashboard-main-objects-model">
        <div className="admin-dashboard-main-objects-model-head">
            <div className="admin-dashboard-main-objects-model-name">
                {model.name}
            </div>
            <Avatar classes={{root: 'admin-dashboard-main-objects-model-head-badge'}}
                    sx={{width: 24, height: 24, bgcolor: '#757575'}}
            >
                Объектов: {model.count}
            </Avatar>
        </div>
        <div className="admin-dashboard-main-objects-model-table-name">
            Кол-во: <span>{model.count}</span>
        </div>
        {
            showColumns ? <div className="admin-dashboard-main-objects-model-table-columns">
                <div className="admin-dashboard-main-objects-model-table-columns-left">
                    {model.columns.map((column, index) => {
                        return <div key={index} className="admin-dashboard-main-objects-model-table-column">
                            <div className="admin-dashboard-main-objects-model-table-column-name">{column.name}</div>
                        </div>
                    })}
                </div>
                <div className="admin-dashboard-main-objects-model-table-columns-right">
                    {model.columns.map((column, index) => {
                        return <div key={index} className="admin-dashboard-main-objects-model-table-column">
                            <div className="admin-dashboard-main-objects-model-table-column-type">{column.type}</div>
                        </div>
                    })}
                </div>
            </div> : null
        }
        <Button classes={{root: 'admin-dashboard-main-objects-model-table-button'}}
                variant="outlined" onClick={() => openModel(model.name)}>
            Открыть
        </Button>
        <Button classes={{root: 'admin-dashboard-main-objects-model-table-button-add'}}
                variant="outlined" onClick={() => addModel(model.name)}>
            Добавить
        </Button>
    </div>
}

class AdminPanel extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            title: 'Admin Panel',

            page: props.page,
            models: [],
            isMenuOpen: false,
            showColumns: false,

            currentModel: null
        }


        this.setTitle = (title) => {
            if (this.state.title !== title) this.setState({title: title})
        }

        this.showFieldsSwitch = generateSwitch('Показать поля', this.state.showColumns, () => {
            this.setState({showColumns: !this.state.showColumns})
        })

        this.setMenu = (status) => () => {
            this.setState({isMenuOpen: status});
        };

        this.openModel = (modelName) => {
            let model = null;
            this.state.models.forEach(tModel => {
                if (tModel.name === modelName) {
                    model = tModel
                }
            })
            window.location = `/admin/panel/models/${model.table_name}/`;
        }

        this.addModel = (modelName) => {
            let model = null;
            this.state.models.forEach(tModel => {
                if (tModel.name === modelName) {
                    model = tModel
                }
            })
            window.location = `/admin/panel/models/${model.table_name}/entity/create/`;
        }

        this.findModel = () => {
            let pathname = window.location.pathname
            let foundModel = null
            this.state.models.forEach(model => {
                if (pathname.includes(model.table_name)) {
                    foundModel = model
                }
            })
            this.setState({currentModel: foundModel})
        }

        this.adminTemplate = this.adminTemplate.bind(this);
    }

    componentDidMount() {
        API.adminInternal()
            .then(data => {
                this.setState({
                    models: data.data
                }, () => {
                    if (this.state.page === 'models') this.findModel()
                })
            })
    }

    adminTemplate(content) {
        return <div className="customer-dashboard-container">
            <div className="customer-dashboard-menu">
                <IconButton classes={{root: 'customer-open-menu'}} aria-label="customer-open-menu"
                            onClick={this.setMenu(true)}>
                    <MenuOutlinedIcon/>
                </IconButton>
                <Drawer open={this.state.isMenuOpen} onClose={this.setMenu(false)}>
                    {renderMenu(this.setMenu)}
                </Drawer>
            </div>
            <div className="admin-dashboard-content">
                <h1>{this.state.title}</h1>
                {content}
            </div>
        </div>
    }

    render() {
        let content = null;

        content = <div className="admin-dashboard-main-objects-container">
            <div className="admin-dashboard-main-objects-header">
                <div className="admin-dashboard-main-objects-title">Таблицы</div>
                {this.showFieldsSwitch}
            </div>
            <div className="admin-dashboard-main-objects">
                {
                    this.state.models.map((model, index) => {
                        return renderObject(index, model, this.state.showColumns, this.openModel, this.addModel)
                    })
                }
            </div>
        </div>


        return this.adminTemplate(content)
    }
}

export default AdminPanel;


