import React from "react";
import '../style/Documents.css';
import {ReactComponent as LogoIcon} from "../images/logo.svg";
import {Viewer, Worker} from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import {SERVER_URL} from "../config";


class DocumentsPublicOffer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {}
    }

    componentDidMount() {

    }

    render() {
        return <div className="documents-public-offer-page">
            <div className="anonymous-pubhub-header">
                <div className="HeaderMenu" style={{backgroundColor: '#fff'}}>
                    <div className="HeaderMenu-container HeaderMenu-container-center">
                        <a className="HeaderMenu-logo" href="/">
                            <LogoIcon/>
                        </a>
                    </div>
                </div>
            </div>
            <div className="documents-public-offer-page-title">
                {this.props.title}
            </div>
            <div className="documents-public-offer-page-pdf">
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                    <div className="documents-public-offer-page-pdf-inner">
                        <Viewer fileUrl={SERVER_URL + this.props.url + "?hash=" + parseInt(Math.random() * 10 ** 12)}></Viewer>
                    </div>
                </Worker>
            </div>
        </div>
    }
}

export default DocumentsPublicOffer;
