import React from 'react'
import '../../../style/screenshotEngine.css'
import {useScreenshot} from "use-screenshot-hook";


function TakeScreenshot() {
    const {image, takeScreenshot} = useScreenshot();
    return (
        <div className="TakeScreenshot">
            <h1>Hello World!</h1>
            <button id="take-screenshot" onClick={() => takeScreenshot()}>screenshot</button>
            {image && <img id="screenshot-image" src={image} alt="screenshot"/>}
        </div>
    );
}

export default TakeScreenshot;