import React from "react";
import '../style/SupportPage.css';
import ImageTest1 from '../images/imageTest1.png';
import ImageTest2 from '../images/imageTest2.png';

class TestPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            images: [
                ImageTest1,
                ImageTest2
            ],
            imageCurrent: 0
        }
    }

    componentDidMount() {

    }

    render() {
        return <div className="main-test-container"
                    onClick={() => this.setState({imageCurrent: this.state.imageCurrent === 0 ? 1 : 0})}>
            <img src={this.state.images[this.state.imageCurrent]} alt="preview"/>
        </div>
    }
}

export default TestPage;
