import React, {useState} from 'react';
import cl from './landing-faq.module.css';
import {ReactComponent as CustomIconChevronDown} from '../../../../../images/customIconChevronDown.svg';
import Contacts from './components/contacts/contacts.component';

function LandingFaq() {
  const [openedAnswerIds, setOpenAnswerId] = useState([]);

  const isOpen = (currentId) => openedAnswerIds.some((id) => id === currentId);

  return (
    <div className={cl.faqAndFooter}>
      <div className={cl.faqWrapper}>
        <div className={cl.faqContent}>
          <div className={cl.faqComposition}>
            <h2 className={cl.faqTitle}>Вопросы и&nbsp;ответы</h2>

            <div className={cl.qAndAWrapper}>
              <div
                className={cl.block}
                onClick={() =>
                  isOpen(1)
                    ? setOpenAnswerId([
                        ...openedAnswerIds.filter((id) => id !== 1),
                      ])
                    : setOpenAnswerId([...openedAnswerIds, 1])
                }
              >
                <div className={cl.header}>
                  <span className={cl.q}>
                    Зачем мне меню-борды? Я&nbsp;пишу всё на&nbsp;бумажных
                    ценниках или доске.
                  </span>
                  <CustomIconChevronDown
                    className={cl.chevron}
                    style={
                      isOpen(1)
                        ? {transform: `rotate(180deg) translateX(0.25em)`}
                        : {}
                    }
                  />
                </div>

                {openedAnswerIds.some((id) => id === 1) && (
                  <span className={cl.a}>
                    Меню-борды упрощают обновление меню, делают информацию более
                    заметной и&nbsp;привлекательной для гостей, что увеличит
                    продажи и&nbsp;улучшит впечатление от&nbsp;посещения вашей
                    пивной.
                  </span>
                )}
              </div>

              <div
                className={cl.block}
                onClick={() =>
                  isOpen(2)
                    ? setOpenAnswerId([
                        ...openedAnswerIds.filter((id) => id !== 2),
                      ])
                    : setOpenAnswerId([...openedAnswerIds, 2])
                }
              >
                <div className={cl.header}>
                  <span className={cl.q}>Что нужно для подключения?</span>
                  <CustomIconChevronDown
                    className={cl.chevron}
                    style={
                      isOpen(2)
                        ? {transform: `rotate(180deg) translateX(0.25em)`}
                        : {}
                    }
                  />
                </div>

                {openedAnswerIds.some((id) => id === 2) && (
                  <span className={cl.a}>
                    Смарт-телевизор или приставка на&nbsp;базе Android
                    и&nbsp;подключение к&nbsp;интернету на&nbsp;точке.
                  </span>
                )}
              </div>
              <div
                className={cl.block}
                onClick={() =>
                  isOpen(3)
                    ? setOpenAnswerId([
                        ...openedAnswerIds.filter((id) => id !== 3),
                      ])
                    : setOpenAnswerId([...openedAnswerIds, 3])
                }
              >
                <div className={cl.header}>
                  <span className={cl.q}>
                    Вы&nbsp;предоставляете телевизоры и&nbsp;приставки?
                  </span>
                  <CustomIconChevronDown
                    className={cl.chevron}
                    style={
                      isOpen(3)
                        ? {transform: `rotate(180deg) translateX(0.25em)`}
                        : {}
                    }
                  />
                </div>

                {openedAnswerIds.some((id) => id === 3) && (
                  <span className={cl.a}>
                    Нет, телевизоры или приставки вы&nbsp;приобретаете
                    самостоятельно, но&nbsp;мы&nbsp;можем помочь определиться
                    с&nbsp;моделью и&nbsp;версией&nbsp;ПО для устройств.
                  </span>
                )}
              </div>
              <div
                className={cl.block}
                onClick={() =>
                  isOpen(4)
                    ? setOpenAnswerId([
                        ...openedAnswerIds.filter((id) => id !== 4),
                      ])
                    : setOpenAnswerId([...openedAnswerIds, 4])
                }
              >
                <div className={cl.header}>
                  <span className={cl.q}>
                    Можно получить доступ до&nbsp;оплаты?
                  </span>
                  <CustomIconChevronDown
                    className={cl.chevron}
                    style={
                      isOpen(4)
                        ? {transform: `rotate(180deg) translateX(0.25em)`}
                        : {}
                    }
                  />
                </div>

                {openedAnswerIds.some((id) => id === 4) && (
                  <span className={cl.a}>
                    Да, мы&nbsp;предоставляем бесплатный пятидневный период.
                  </span>
                )}
              </div>
              <div
                className={cl.block}
                onClick={() =>
                  isOpen(5)
                    ? setOpenAnswerId([
                        ...openedAnswerIds.filter((id) => id !== 5),
                      ])
                    : setOpenAnswerId([...openedAnswerIds, 5])
                }
              >
                <div className={cl.header}>
                  <span className={cl.q}>
                    Что будет при отключении интернета на&nbsp;точке?
                  </span>
                  <CustomIconChevronDown
                    className={cl.chevron}
                    style={
                      isOpen(5)
                        ? {transform: `rotate(180deg) translateX(0.25em)`}
                        : {}
                    }
                  />
                </div>

                {openedAnswerIds.some((id) => id === 5) && (
                  <span className={cl.a}>
                    Ничего, меню продолжит отображаться, но&nbsp;не&nbsp;будет
                    обновляться до&nbsp;восстановления подключения.
                  </span>
                )}
              </div>

              <div
                className={cl.block}
                onClick={() =>
                  isOpen(6)
                    ? setOpenAnswerId([
                        ...openedAnswerIds.filter((id) => id !== 6),
                      ])
                    : setOpenAnswerId([...openedAnswerIds, 6])
                }
              >
                <div className={cl.header}>
                  <span className={cl.q}>Сколько это стоит?</span>
                  <CustomIconChevronDown
                    className={cl.chevron}
                    style={
                      isOpen(6)
                        ? {transform: `rotate(180deg) translateX(0.25em)`}
                        : {}
                    }
                  />
                </div>

                {openedAnswerIds.some((id) => id === 6) && (
                  <span className={cl.a}>
                    Все зависит от&nbsp;количества точек и&nbsp;зачастую
                    предложение формируется индивидуально, вы&nbsp;можете
                    попросить расчет у&nbsp;менеджера.
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className={cl.contactsBlock}>
            <Contacts />
          </div>
        </div>

        <div className={cl.realFooter}>
          <span>показываем пиво</span>
          <span>с&nbsp;2020 года</span>
        </div>
      </div>
    </div>
  );
}

export default LandingFaq;
