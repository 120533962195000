import React from "react";
import '../style/adminPanel.css';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import {Button, FormControlLabel, IconButton, Switch} from "@mui/material";
import API from "../api";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Utils from "./utils";

function generateSwitch(label, value, setNewValue) {
    return <FormControlLabel
        control={<Switch value={value} onClick={setNewValue}/>}
        label={label}/>
}

function renderMenu(setMenuStatus) {
    return <Box sx={{width: 250}} role="presentation" onClick={setMenuStatus(false)}>
        <List>
            {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (<ListItem key={text} disablePadding>
                <ListItemButton>
                    <ListItemIcon>
                        {index % 2 === 0 ? <InboxIcon/> : <MailIcon/>}
                    </ListItemIcon>
                    <ListItemText primary={text}/>
                </ListItemButton>
            </ListItem>))}
        </List>
        <Divider/>
        <List>
            {['All mail', 'Trash', 'Spam'].map((text, index) => (<ListItem key={text} disablePadding>
                <ListItemButton>
                    <ListItemIcon>
                        {index % 2 === 0 ? <InboxIcon/> : <MailIcon/>}
                    </ListItemIcon>
                    <ListItemText primary={text}/>
                </ListItemButton>
            </ListItem>))}
        </List>
    </Box>
}

function renderEntities(modelData, openEntityEdit) {
    let entities = []
    let columns = []
    let rows = []

    if (modelData !== null) {
        entities = modelData.items;
        columns = modelData.columns;
        columns.sort((a, b) => a.index - b.index)

        rows = []
        entities.forEach(entity => {
            let row = []
            columns.forEach(column => {
                row.push(entity[column.name])
            })
            rows.push(row)
        })
    }

    return <div className="admin-dashboard-main-entities">
        <TableContainer component={Paper}>
            <Table sx={{minWidth: 650}} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        {
                            columns.map((column, index) => {
                                let columnName = column.name.replace('_', ' ')
                                columnName = Utils.titleString(columnName)
                                return <TableCell key={index}>{columnName}</TableCell>
                            })
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row, index) => (<TableRow
                        classes={{root: 'admin-dashboard-main-entities-table-row'}}
                        key={row[0]}
                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                        onClick={() => openEntityEdit(row[0])}
                    >
                        <TableCell component="th" scope="row">
                            {row[0]}
                        </TableCell>
                        {
                            row.slice(1).map((item, index) => <TableCell key={index} align="left">{item}</TableCell>)
                        }
                    </TableRow>))}
                </TableBody>
            </Table>
        </TableContainer>
    </div>
}

function renderEntitiesMobile(modelData, currentModel, openEntityEdit) {
    let entities = []
    let columns = []
    let rows = []

    if (modelData !== null) {
        entities = modelData.items;
        columns = modelData.columns;
        columns.sort((a, b) => a.index - b.index)

        rows = []
        entities.forEach(entity => {
            let row = []
            columns.forEach(column => {
                row.push(entity[column.name])
            })
            rows.push(row)
        })
    }

    return <div className="admin-dashboard-main-entities">
        {entities.map((entity, index) => {
            return <div key={index} className="admin-dashboard-main-mobile-entity"
                        onClick={() => openEntityEdit(entity[columns[0].name])}>
                <div className="admin-dashboard-main-mobile-entity-name">
                    {currentModel.name} {entity[columns[0].name]}
                </div>
                {columns.map((column, index) => {
                    return <div key={index} className="admin-dashboard-main-mobile-entity-container">
                        <div className="admin-dashboard-main-mobile-entity-key">
                            {Utils.titleString(column.name)}:
                        </div>
                        <div className="admin-dashboard-main-mobile-entity-value">
                            {entity[column.name]}
                        </div>
                    </div>
                })}
            </div>
        })}
    </div>
}

class AdminPanelModels extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            title: '',

            page: props.page, models: [], isMenuOpen: false, showColumns: false,

            currentModel: null,
            modelData: null,

            isMobile: Utils.isDeviceMobile()
        }

        this.setTitle = (title) => {
            if (this.state.title !== title) this.setState({title: title})
        }

        this.showFieldsSwitch = generateSwitch('Показать поля', this.state.showColumns, () => {
            this.setState({showColumns: !this.state.showColumns})
        })

        this.setMenu = (status) => () => {
            this.setState({isMenuOpen: status});
        };

        this.openModel = (modelName) => {
            let model = null;
            this.state.models.forEach(tModel => {
                if (tModel.name === modelName) {
                    model = tModel
                }
            })
            window.open(`/admin/panel/models/${model.table_name}/`, '_blank')
        }

        this.findModel = () => {
            let pathname = window.location.pathname
            let foundModel = null
            this.state.models.forEach(model => {
                if (pathname.includes(model.table_name)) {
                    foundModel = model
                }
            })
            this.setState({currentModel: foundModel})
            this.setTitle(`${foundModel.name}`)
            Utils.setPageTitle(`${foundModel.name}`)

            API.adminInternalModel(foundModel.table_name, false)
                .then(data => {
                    this.setState({modelData: data})
                })
        }

        this.openEntityEdit = (fieldValue) => {
            window.location = `/admin/panel/models/${this.state.currentModel.table_name}/entity/${fieldValue}/`;
        }

        this.openMainPage = () => {
            window.location = '/admin/panel/';
        }

        this.addObject = () => {
            window.location = `/admin/panel/models/${this.state.currentModel.table_name}/entity/create/`
        }

        this.adminTemplate = this.adminTemplate.bind(this);
    }

    componentDidMount() {
        API.adminInternal()
            .then(data => {
                this.setState({
                    models: data.data
                }, () => {
                    this.findModel()
                })
            })
    }

    adminTemplate(content) {
        return <div className="customer-dashboard-container">
            <div className="customer-dashboard-menu">
                <IconButton classes={{root: 'customer-open-menu'}} aria-label="customer-open-menu"
                            onClick={this.setMenu(true)}>
                    <MenuOutlinedIcon/>
                </IconButton>
                <Drawer open={this.state.isMenuOpen} onClose={this.setMenu(false)}>
                    {renderMenu(this.setMenu)}
                </Drawer>
            </div>
            <div className="admin-dashboard-content admin-models-dashboard-content">
                <div className="admin-dashboard-content-internal">
                    <Button classes={{root: 'admin-dashboard-main-back-button'}}
                            variant="text" onClick={this.openMainPage}>
                        Вернуться
                    </Button>
                    <div className="admin-dashboard-content-internal-header">
                        <div className="admin-dashboard-content-internal-header-left">
                            <h1>{this.state.title}</h1>
                            <p>Просмотр объектов и управление ими.</p>
                        </div>
                        <div className="admin-dashboard-content-internal-header-right">
                            <Button classes={{root: 'admin-dashboard-main-add-button'}}
                                    variant="outlined" onClick={this.addObject}>
                                Добавить новый объект
                            </Button>
                        </div>
                    </div>
                    {content}

                </div>
            </div>
        </div>
    }

    render() {
        let content = null;

        if (this.state.modelData) {
            content = <div className="admin-dashboard-models-main-objects-container">
                <div className="admin-dashboard-main-entities-container">
                    {
                        this.state.isMobile ?
                            renderEntitiesMobile(this.state.modelData, this.state.currentModel, this.openEntityEdit) :
                            renderEntities(this.state.modelData, this.openEntityEdit)
                    }
                </div>
            </div>
        }


        return this.adminTemplate(content)
    }
}

export default AdminPanelModels;


