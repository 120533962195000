import {get, post, url} from '../../../../../../utils/request';

export default class PaymentService {
  static async getSubscriptionInfo({period, stores}) {
    const response = await get(
      url('/api/billing/subscription/', {period: period, stores: stores})
    );

    return response.billing;
  }

  static async createInvoice({period, stores}) {
    const response = await post(
      url('/api/billing/invoice/create/', {period: period, stores: stores})
    );

    return response;
  }

  static async getInvoiceStatus() {
    const response = await get(url('/api/billing/invoice/'));

    return response;
  }

  static async cancelInvoice() {
    const response = await post(url('/api/billing/invoice/cancel/'));

    return response;
  }

  static async getInvoiceFile() {
    const response = await get(url('/api/billing/invoice/download/'));

    return response;
  }

  static async approveOffer() {
    const response = await post(url('/api/billing/documents/offer/'));

    return response;
  }

  static async approvePrivacy() {
    const response = await post(url('/api/billing/documents/privacy/'));

    return response;
  }

  static async getCurrentSubscriptions() {
    const response = await get(url('/api/billing/stores/subscription/'));

    return response;
  }

  static async postCancelBilling() {
    const response = await post(url('/api/billing/invoice/cancel/'));

    return response;
  }
}
