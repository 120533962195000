import React from "react";
import '../../../style/multiRadioButton.css'
import {ReactComponent as MissingIcon} from "../../../images/missing.svg";


function MultiRadioButton({title, values, selected, selectValue, colors, description}) {
    let classList = ['MultiRadioButton-elements']
    if (Object.keys(values).length > 3) classList.push('MultiRadioButton-elements-many')
    classList = classList.join(' ')

    return <div className="MultiRadioButton-container">
        <div className="MultiRadioButton-title">{title}</div>
        <div className={classList}>
            {Array.from(Object.keys(values)).map((value, index) => {
                let classList = ["MultiRadioButton-element"]
                if (String(selected) === String(value)) classList.push('MultiRadioButton-element-selected')
                let objectValue = values[value];
                let icon = objectValue === 'missing'

                return <div key={index} className={classList.join(' ')}
                            style={colors && !icon ? {background: objectValue} : null}
                            onClick={() => selectValue(value)}>
                    {icon ? <MissingIcon/> : (colors ? null : objectValue)}
                </div>
            })}
        </div>
        {description ? <div className="MultiRadioButton-description">{description}</div> : null}
    </div>
}

export default MultiRadioButton;