import React from 'react';
import cl from './additionals.module.css';
import {ReactComponent as CustomBlockArrowRight} from '../../../../../../../images/customBlockArrowRight.svg';

function CardWithAdditionalContent({header, description}) {
  return (
    <div className={cl.card}>
      <span
        className={cl.cardHeader}
        dangerouslySetInnerHTML={{__html: header}}
      ></span>
      <span
        className={cl.cardDescription}
        dangerouslySetInnerHTML={{__html: description}}
      ></span>
    </div>
  );
}

function LandingBodyAdditionals() {
  return (
    <>
      <h2 className={cl.additionalsTitle}>Дополнительно</h2>

      <div className={cl.additionalContentWrapper}>
        <div className={cl.additionalContentColumn}>
          <CardWithAdditionalContent
            header={'Доступность сервиса <br />98% времени'}
            description={'Согласно оферте'}
          />
          <CardWithAdditionalContent
            header={'Постоянные обновления <br />и&nbsp;улучшения'}
            description={
              'Мы&nbsp;постоянно собираем с&nbsp;клиентов фидбек <br />и&nbsp;предложения. За&nbsp;счёт этого нередко <br />исправляется много ошибок и&nbsp;добавляются <br />новые фичи под узкие нужды бизнесов'
            }
          />

          <div className={cl.arrow}>
            <CustomBlockArrowRight />
          </div>
        </div>

        <div className={cl.additionalContentColumn}>
          <CardWithAdditionalContent
            header={'Готовый к&nbsp;нагрузкам сервер'}
            description={'Ничего не&nbsp;отвалится посреди дня'}
          />
          <CardWithAdditionalContent
            header={
              'Можем придумать <br />что-нибудь специально <br />для одной сети или магазина'
            }
            description={
              'Как правило, локальные доработки для одного <br />клиента не&nbsp;входят в&nbsp;подписку, соответственно <br />разрабатываются за&nbsp;отдельную плату. Не <br />относится к&nbsp;доработкам для всех клиентов'
            }
          />
        </div>

        <div className={cl.additionalContentColumn}>
          <CardWithAdditionalContent
            header={'Настроим всё оборудование'}
            description={
              'Приедем-подключим-настроим, <br />сломалось&nbsp;&mdash; починим'
            }
          />
          <CardWithAdditionalContent
            header={'Готовая команда специалистов <br />и&nbsp;техподдержка'}
            description={
              'Команда профессионалов по&nbsp;цене подписки <br />вместо найма своих'
            }
          />
        </div>
      </div>
    </>
  );
}

export default LandingBodyAdditionals;
