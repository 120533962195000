import React from 'react';
import cl from './price-page.module.css';
import {ReactComponent as LogoIcon} from '../../../images/logo.svg';
import {ReactComponent as DesignerGenius} from '../../../images/customIconDesignerGenius.svg';

function PricePage() {
  return (
    <div className={cl.meta}>
      <div className={cl.header}>
        <a className={cl.HeaderMenuLogo} href='/'>
          <LogoIcon />
        </a>
      </div>

      <div className={cl.body}>
        <span className={cl.h}>Цены</span>

        <div className={cl.priceComposition}>
          <div className={cl.priceByStores}>
            <div className={cl.priceHeader}>
              <span className={cl.storesCountName}>Одна точка</span>

              <div className={cl.rectangleWrapper}>
                <div className={cl.rectangleBlack}></div>
                <div className={cl.rectangleWhite}></div>
                <div className={cl.rectangleWhite}></div>
                <div className={cl.rectangleWhite}></div>
                <div className={cl.rectangleWhite}></div>
                <div className={cl.rectangleWhite}></div>
              </div>
            </div>

            <div className={cl.priceFooter}>
              <div className={cl.priceInfoWrapper}>
                <span className={cl.price}>5&nbsp;840&nbsp;₽</span>
                <span className={cl.info}>в&nbsp;месяц за&nbsp;точку</span>
              </div>

              <span className={cl.pricePerMonth}>
                ~ 1&nbsp;460&nbsp;₽/ экран
              </span>
            </div>
          </div>

          <div>
            <div className={cl.priceByStores}>
              <div className={cl.priceHeader}>
                <span className={cl.storesCountName}>
                  2 точки <span className={cl.wowDesigner}>Топ</span>
                </span>

                <div className={cl.rectangleWrapper}>
                  <div className={cl.rectangleBlack}></div>
                  <div className={cl.rectangleBlack}></div>
                  <div className={cl.rectangleWhite}></div>
                  <div className={cl.rectangleWhite}></div>
                  <div className={cl.rectangleWhite}></div>
                  <div className={cl.rectangleWhite}></div>
                </div>
              </div>

              <div className={cl.priceFooter}>
                <div className={cl.priceInfoWrapper}>
                  <span className={cl.price}>
                    5&nbsp;160&nbsp;₽ <span>5&nbsp;240&nbsp;₽</span>
                  </span>
                  <span className={cl.info}>в&nbsp;месяц за&nbsp;точку</span>
                </div>

                <div className={cl.pricePerMonthGreen}>
                  ~ 1&nbsp;280&nbsp;₽/ экран
                </div>
              </div>
            </div>
          </div>

          <div className={cl.priceByStores}>
            <div className={cl.priceHeader}>
              <span className={cl.storesCountName}>3 точки</span>

              <div className={cl.rectangleWrapper}>
                <div className={cl.rectangleBlack}></div>
                <div className={cl.rectangleBlack}></div>
                <div className={cl.rectangleBlack}></div>
                <div className={cl.rectangleWhite}></div>
                <div className={cl.rectangleWhite}></div>
                <div className={cl.rectangleWhite}></div>
              </div>
            </div>

            <div className={cl.priceFooter}>
              <div className={cl.priceInfoWrapper}>
                <span className={cl.price}>4&nbsp;820&nbsp;₽</span>
                <span className={cl.info}>в&nbsp;месяц за&nbsp;точку</span>
              </div>

              <div className={cl.pricePerMonth}>~ 1&nbsp;200&nbsp;₽/ экран</div>
            </div>
          </div>

          <div className={cl.priceByStores}>
            <div className={cl.priceHeader}>
              <span className={cl.storesCountName}>4 точки</span>

              <div className={cl.rectangleWrapper}>
                <div className={cl.rectangleBlack}></div>
                <div className={cl.rectangleBlack}></div>
                <div className={cl.rectangleBlack}></div>
                <div className={cl.rectangleBlack}></div>
                <div className={cl.rectangleWhite}></div>
                <div className={cl.rectangleWhite}></div>
              </div>
            </div>

            <div className={cl.priceFooter}>
              <div className={cl.priceInfoWrapper}>
                <span className={cl.price}>4&nbsp;410&nbsp;₽</span>
                <span className={cl.info}>в&nbsp;месяц за&nbsp;точку</span>
              </div>

              <div className={cl.pricePerMonth}>~ 1&nbsp;100&nbsp;₽/ экран</div>
            </div>
          </div>

          <div className={cl.priceByStores}>
            <div className={cl.priceHeader}>
              <span className={cl.storesCountName}>5 точек</span>

              <div className={cl.rectangleWrapper}>
                <div className={cl.rectangleBlack}></div>
                <div className={cl.rectangleBlack}></div>
                <div className={cl.rectangleBlack}></div>
                <div className={cl.rectangleBlack}></div>
                <div className={cl.rectangleBlack}></div>
                <div className={cl.rectangleWhite}></div>
              </div>
            </div>

            <div className={cl.priceFooter}>
              <div className={cl.priceInfoWrapper}>
                <span className={cl.price}>3&nbsp;990&nbsp;₽</span>
                <span className={cl.info}>в&nbsp;месяц за&nbsp;точку</span>
              </div>

              <div className={cl.pricePerMonth}>~ 1&nbsp;000&nbsp;₽/ экран</div>
            </div>
          </div>

          <div className={cl.priceByStores}>
            <div className={cl.priceHeader}>
              <span className={cl.storesCountName}>6 точек</span>

              <div className={cl.rectangleWrapper}>
                <div className={cl.rectangleBlack}></div>
                <div className={cl.rectangleBlack}></div>
                <div className={cl.rectangleBlack}></div>
                <div className={cl.rectangleBlack}></div>
                <div className={cl.rectangleBlack}></div>
                <div className={cl.rectangleBlack}></div>
              </div>
            </div>

            <div className={cl.priceFooter}>
              <div className={cl.priceInfoWrapper}>
                <span className={cl.price}>3&nbsp;900&nbsp;₽</span>
                <span className={cl.info}>в&nbsp;месяц за&nbsp;точку</span>
              </div>

              <div className={cl.pricePerMonth}>~ 950&nbsp;₽/ экран</div>
            </div>
          </div>

          <div className={cl.priceByStores}>
            <div className={cl.priceHeader}>
              <span className={cl.storesCountName}>от&nbsp;6&nbsp;точек</span>

              <div className={cl.rectangleWrapperBig}>
                <div className={cl.rectangleBlack}></div>
                <div className={cl.rectangleWhite}></div>
                <div className={cl.rectangleBlack}></div>
                <div className={cl.rectangleWhite}></div>
                <div className={cl.rectangleBlack}></div>
                <div className={cl.rectangleWhite}></div>
                <div className={cl.rectangleWhite}></div>
                <div className={cl.rectangleBlack}></div>
                <div className={cl.rectangleWhite}></div>
                <div className={cl.rectangleBlack}></div>
                <div className={cl.rectangleWhite}></div>
                <div className={cl.rectangleBlack}></div>
                <div className={cl.rectangleBlack}></div>
                <div className={cl.rectangleWhite}></div>
                <div className={cl.rectangleBlack}></div>
                <div className={cl.rectangleWhite}></div>
                <div className={cl.rectangleBlack}></div>
                <div className={cl.rectangleWhite}></div>
              </div>
            </div>

            <div className={cl.priceFooter}>
              <span className={cl.individual}>Считаем индивидуально</span>
            </div>
          </div>

          <div>
            <span className={cl.disignerGenius}>
              <DesignerGenius />
            </span>
            <div className={cl.priceByStores}>
              <div className={cl.priceHeader}></div>

              <div className={cl.priceFooterNextLvl}>
                <span className={cl.subHead}>скидки при покупке</span>

                <div className={cl.discountWrap}>
                  <span style={{fontWeight: '600'}} className={cl.discount}>
                    &mdash;&nbsp;5%&nbsp;
                    <span style={{fontWeight: '400'}}>
                      от&nbsp;3&nbsp;месяцев
                    </span>
                  </span>

                  <span style={{fontWeight: '600'}} className={cl.discount}>
                    &mdash;&nbsp;10%&nbsp;
                    <span style={{fontWeight: '400'}}>
                      от&nbsp;6&nbsp;месяцев
                    </span>
                  </span>

                  <span style={{fontWeight: '600'}} className={cl.discount}>
                    &mdash;&nbsp;15%&nbsp;
                    <span style={{fontWeight: '400'}}>
                      от&nbsp;12&nbsp;месяцев
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PricePage;
