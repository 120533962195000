import React from 'react';
import './aside-card.component.css';
import {ReactComponent as UnfilteredBeerIcon} from '../../../../../images/unfilteredBeerIcon.svg';
import {SERVER_URL} from "../../../../../config";

const beerTypes = {
  dark: 'beer-dot-dark',
  light: 'beer-dot-light',
  red: 'beer-dot-red',
  sweet: 'beer-dot-sweet',
};

const beerLabels = {
  dark: 'тёмное',
  light: 'светлое',
  sweet: 'сладкое',
  red: 'красное',
};

function AsideCard({menuItem, crane}) {
  const tags = [
    {color: menuItem.tag1_color, name: menuItem.tag1_name},
    {
      color: menuItem.tag2_color,
      name: menuItem.tag2_name,
    },
    {color: menuItem.tag3_color, name: menuItem.tag3_name},
    {color: menuItem.tag4_color, name: menuItem.tag4_name},
  ].filter((tag) => tag.name);

  const imageStyles = crane.is_product_end
    ? {filter: 'grayscale(100%)', opacity: '0.8'}
    : null;

  return (
    <div className='card-wrap'>
      {/* Картинка с позицией уебанской */}
      {menuItem.image && menuItem.image.url ? (
        <img alt="avatar" className='image' style={imageStyles} src={SERVER_URL + menuItem.image.url.split('?')[0]} />
      ) : null}

      {/* Тело карточки */}
      <div className='beer-card-wrapper'>
        <div className='card'>
          {/* номер крана*/}
          <div className='crane-number-wrap'>
            {crane.number ? (
              <span className='tap-number'>{crane.number}</span>
            ) : null}
          </div>

          <div className='divider-for-gap-32px-vertical'></div>

          <div className='card-info-body'>
            <div className='brewery-sort-wrapper'>
              {/* пивоварня */}
              <div className='brewery'>{menuItem.brewery}</div>

              <div className='divider-for-gap-4px-vertical'></div>

              {/* Наименование позиции и сорт */}
              <div className='beer-sort'>
                <div className='beer-sort-l'>
                  {menuItem.name.split(', ')[0]}
                </div>
                <div className='beer-sort-s'>
                  {menuItem.name.split(', ')[1]}
                </div>
              </div>
            </div>

            <div className='divider-for-gap-8px-vertical'></div>

            {/* Блок с тэгами */}
            <div className='beer-item-aside-tags'>
              {Array.from(tags).map((tag, index) => {
                let style = {backgroundColor: tag.color};
                if (tag.color !== 'null') {
                  style.display = 'flex';
                }
                return (
                  <div
                    key={index}
                    className='beer-item-aside-tag'
                    style={style}
                  >
                    {tag.name}
                  </div>
                );
              })}
            </div>

            <div className='divider-for-gap-8px-vertical'></div>

            {/* Плотность, ИБУ, алкоголь */}
            <div className='beer-alcohol'>
              <span className='alcohol_degrees'>
                {menuItem.alcohol_degrees
                  ? `${menuItem.alcohol_degrees}%`
                  : 'Б/А'}
              </span>
              <div className='divider-for-gap-6px-horizontal'></div>
              {menuItem.bitterness_scale ? (
                <>
                  <span className='beer-aside-item-ibu-wrapper'>
                    IBU {menuItem.bitterness_scale}
                  </span>
                  <div className='divider-for-gap-6px-horizontal'></div>
                </>
              ) : null}
              <span className='alcohol_degrees'>
                {menuItem.alcohol_density}%
              </span>
            </div>
          </div>
        </div>

        {/* Цена/закончилось и всякая поебота */}
        <div className='beer-info'>
          {/* Всякая поебота */}
          <div className='beer-type'>
            <div className='beer-item-aside-description-wrapper'>
              {menuItem.filtered === false ? (
                <div className='beer-item-aside-unfiltered-wrapper'>
                  <UnfilteredBeerIcon />
                  <span className='beer-item-aside-typography'>Нефильтр</span>
                </div>
              ) : null}

              <div className='divider-for-gap-4px-vertical'></div>

              <div className='beer-type-wrapper'>
                <div className={beerTypes[menuItem.beer_color]}></div>

                <div className='beer-item-aside-typography'>
                  {beerLabels[menuItem.beer_color] ? beerLabels[menuItem.beer_color][0].toUpperCase() +
                    beerLabels[menuItem.beer_color].slice(1): null}
                </div>
              </div>
            </div>
          </div>

          {/* цена или закончилось */}
          {crane.is_product_end ? (
            <div className='card-beer-ended'>закончилось</div>
          ) : (
            <div className='price'>{menuItem.price} ₽</div>
          )}
        </div>
      </div>
    </div>
  );
}

export default AsideCard;
