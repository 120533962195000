import React from "react";
import classes from "./my-checkbox.module.css";

function MyCheckboxComponent({ select, disabled, unitId, value }) {
  return (
    <div className={classes.checkboxWrapper}>
      {value === false ? (
        <button
          disabled={disabled}
          value={value}
          onClick={() => {
            select(unitId);
          }}
          className={classes.customCheckbox}
        ></button>
      ) : (
        <button
          disabled={disabled}
          value={value}
          onClick={() => {
            select(unitId);
          }}
          className={classes.customCheckboxActive}
        ></button>
      )}
    </div>
  );
}

export default MyCheckboxComponent;
