import React from 'react';
import cl from './landing-main.module.css';
import {ReactComponent as CustomIconTelegram} from '../../../../../images/telegram.svg';

function LandingMain({openDialog}) {
  return (
    <div className={cl.headerWrapper}>
      <div className={cl.headerMeta}>
        <h1 className={cl.metaTitle}>
          Меню-борды
          <br /> для пивных
          <br /> магазинов
        </h1>

        <span className={cl.metaDescription}>
          Мощный и&nbsp;простой в&nbsp;использовании инструмент для отображения
          меню и&nbsp;управления позициями
        </span>

        <div className={cl.metaActions}>
          <button onClick={() => openDialog()} className={cl.actionWant}>
            Хочу попробовать
          </button>
          {/*<a href='/price' className={cl.actionOnPrice}>*/}
          {/*  Цены*/}
          {/*</a>*/}
        </div>
      </div>

      <div className={cl.photoContentWrapper}>
        <video loop preload muted autoPlay playsInline name='media'>
          <source
            src='https://pubhub.su/assets/videos/landing.content1.mp4'
            type='video/mp4'
          />
        </video>
      </div>

      <div className={cl.contactsWrapperMain}>
        <span className={cl.contactsTitle}>Пишите, звоните</span>

        <div className={cl.contactsComposition}>
          <a href='tel:+79505071316' className={cl.contact}>
            8&nbsp;950&nbsp;507&nbsp;13&nbsp;16
          </a>

          <div className={cl.telegramWrapper}>
            <CustomIconTelegram />
            <a
              target='_blank'
              href='https://t.me/pubhubsu'
              className={cl.contact}
            >
              @pubhubsu
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LandingMain;
