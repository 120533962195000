import React, {useEffect, useState} from 'react';
import classes from './position-dialog.module.css';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import {Dialog, IconButton} from '@mui/material';
import Utils from '../../utils';

/**
 * Компонент модального окна для привязывания пива на мониторе к определенной позиции и экрану магазина
 *
 * Результат применения обрабатывается в родительском компоненте
 */
function PositionDialogComponent({
  onClose,
  onSubmit,
  open,
  storeId,
  menuItemId,
  currentPosition,
}) {
  const [screenPos, setScreenPos] = useState(1);
  const [positionPos, setPositionPos] = useState(null);
  const [additionalPos, setAdditionalPos] = useState(null);
  const [isAditional, setIsAditional] = useState(false);
  const [isRemoveAdditionalPos, setIsRemoveAdditionalPos] = useState(false);
  const [isRemovePositional, setIsRemovePositional] = useState(false);

  useEffect(() => {
    if (currentPosition.length) {
      setScreenPos(1);
      setPositionPos(null);
      setAdditionalPos(null);
      setIsAditional(false);
      setIsRemovePositional(false);
      setIsRemoveAdditionalPos(false);

      for (const {additional, positions, screen} of currentPosition) {
        const additionalPosition =
          additional.find((item) => item === menuItemId) ?? null;
        const position = positions.find((item) => item === menuItemId) ?? null;

        if (additionalPosition !== null || position !== null) {
          setScreenPos(screen);
          setAdditionalPos(additionalPosition);
          setPositionPos(position);

          !additionalPosition || setIsAditional(true);
        }
      }
    }
  }, [currentPosition, menuItemId, open]);

  let screenPosition = currentPosition.find((screen) => screen.screen === screenPos)
  if (!screenPosition) screenPosition = {positions: [], additional: [], screen: 1}
  let totalTablePositions = screenPosition.positions.length
  let totalPagePositions = screenPosition.positions.length + screenPosition.additional.length;

  return (
    <Dialog
      maxWidth={'sm'}
      fullWidth={false}
      scroll={Utils.isDeviceMobile() ? 'paper' : 'body'}
      classes={{root: 'modal-styles-root'}}
      onClose={onClose}
      open={open}
    >
      {currentPosition.length ? (
        <div className={classes.modal}>
          <div className={classes.modalHeader}>
            <div className={classes.modalHeaderTitle}>
              <div className={classes.modalTitle}>Позиция</div>
              <IconButton onClick={() => onClose()}>
                <CloseRoundedIcon />
              </IconButton>
            </div>
            <div className={classes.modalSubtitle}>
              Показывайте нужное вам пиво в нужном месте
            </div>
            <div className={classes.modalSubtitleDescription}>
              Выберите необходимые ячейки
            </div>
          </div>

          <div className={classes.screensWrapper}>
            {currentPosition.map((screen) => (
              <>
                {screen.screen === screenPos ? (
                  <button className={classes.screenButtonActive}>
                    Экран {screen.screen}
                  </button>
                ) : (
                  <button
                    className={classes.screenButton}
                    onClick={() => {
                      setScreenPos(screen.screen);
                    }}
                  >
                    Экран {screen.screen}
                  </button>
                )}
              </>
            ))}
          </div>

          <div className={classes.positionsWrapper}>
            {currentPosition
              .find((screen) => screen.screen === screenPos)
              .positions.map((pos, index) => (
                <>
                  {((pos !== null && pos === positionPos) ||
                    positionPos === index + 1) &&
                  isRemovePositional === false ? (
                    <button
                      className={classes.positionButtonActive}
                      onClick={() => {
                        setAdditionalPos(null);
                        setPositionPos(index + 1);
                        setIsAditional(false);
                        setIsRemoveAdditionalPos(false);
                        setIsRemovePositional(true);
                      }}
                    >
                      <div className={classes.positionNumberActive}>
                        {(screenPosition.screen - 1) * totalPagePositions + index + 1}
                      </div>

                      {pos !== null ? (
                        <div className={classes.positionSpotedActive}>
                          занят
                        </div>
                      ) : null}
                    </button>
                  ) : (
                    <button
                      className={classes.positionButton}
                      onClick={() => {
                        setAdditionalPos(null);
                        setPositionPos(index + 1);
                        setIsAditional(false);
                        setIsRemoveAdditionalPos(false);
                        setIsRemovePositional(false);
                      }}
                    >
                      <div className={classes.positionNumber}>
                        {(screenPosition.screen - 1) * totalPagePositions + index + 1}
                      </div>
                      {pos !== null ? (
                        <div className={classes.positionSpoted}>занят</div>
                      ) : null}
                    </button>
                  )}
                </>
              ))}
          </div>

          <div className={classes.additionalPosContainer}>
            <div className={classes.additionalPosContainerTitle}>
              Большие ячейки
            </div>
            <div className={classes.additionalsWrapper}>
              {currentPosition
                .find((screen) => screen.screen === screenPos)
                .additional.map((pos, index) => (
                  <>
                    {((pos !== null && pos === additionalPos) ||
                      additionalPos === index + 1) &&
                    isRemoveAdditionalPos === false ? (
                      <button
                        className={classes.positionButtonActive}
                        onClick={() => {
                          setPositionPos(null);
                          setAdditionalPos(index + 1);
                          setIsAditional(true);
                          setIsRemoveAdditionalPos(true);
                          setIsRemovePositional(false);
                        }}
                      >
                        <div className={classes.positionNumberActive}>
                          {(screenPosition.screen - 1) * totalPagePositions + totalTablePositions + index + 1}
                        </div>

                        {pos !== null ? (
                          <div className={classes.positionSpotedActive}>
                            занят
                          </div>
                        ) : null}
                      </button>
                    ) : (
                      <button
                        className={classes.positionButton}
                        onClick={() => {
                          setPositionPos(null);
                          setAdditionalPos(index + 1);
                          setIsAditional(true);
                          setIsRemoveAdditionalPos(false);
                          setIsRemovePositional(false);
                        }}
                      >
                        <div className={classes.positionNumber}>
                          {(screenPosition.screen - 1) * totalPagePositions + totalTablePositions + index + 1}
                        </div>
                        {pos !== null ? (
                          <div className={classes.positionSpoted}>занят</div>
                        ) : null}
                      </button>
                    )}
                  </>
                ))}
            </div>
          </div>

          <div className={classes.controls}>
            <div className={classes.controlsDivider}></div>
            <div className={classes.controlsActionsWrapper}>
              <button
                className={classes.actionSubmit}
                onClick={() => {
                  onSubmit({
                    data: {
                      store_id: storeId,
                      screen_number: screenPos,
                      position:
                        isAditional === true
                          ? additionalPos - 1
                          : positionPos - 1,
                      additional: isAditional,
                      menu_item_id:
                        isRemoveAdditionalPos === true ||
                        isRemovePositional === true
                          ? null
                          : menuItemId,
                    },
                  });
                }}
              >
                Применить
              </button>
              <button
                className={classes.actionCancel}
                onClick={() => onClose()}
              >
                Отмена
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </Dialog>
  );
}

export default PositionDialogComponent;
