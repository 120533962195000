import React from "react";
import '../style/adminPanel.css';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import {Button, Checkbox, FormControlLabel, IconButton, Switch, TextField} from "@mui/material";
import API from "../api";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import Utils from "./utils";

function generateSwitch(label, value, setNewValue) {
    return <FormControlLabel
        control={<Switch value={value} onClick={setNewValue}/>}
        label={label}/>
}

function renderMenu(setMenuStatus) {
    return <Box sx={{width: 250}} role="presentation" onClick={setMenuStatus(false)}>
        <List>
            {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (<ListItem key={text} disablePadding>
                <ListItemButton>
                    <ListItemIcon>
                        {index % 2 === 0 ? <InboxIcon/> : <MailIcon/>}
                    </ListItemIcon>
                    <ListItemText primary={text}/>
                </ListItemButton>
            </ListItem>))}
        </List>
        <Divider/>
        <List>
            {['All mail', 'Trash', 'Spam'].map((text, index) => (<ListItem key={text} disablePadding>
                <ListItemButton>
                    <ListItemIcon>
                        {index % 2 === 0 ? <InboxIcon/> : <MailIcon/>}
                    </ListItemIcon>
                    <ListItemText primary={text}/>
                </ListItemButton>
            </ListItem>))}
        </List>
    </Box>
}

function getFieldType(column) {
    let inputType = 'text'
    switch (column.type) {
        case 'Integer':
            inputType = 'number'
            break
        case 'Float':
            inputType = 'number'
            break
        case 'IntegerRange':
            inputType = 'number'
            break
        case 'Time':
            inputType = 'date'
            break
        case 'Date':
            inputType = 'date'
            break
        case 'DateTime':
            inputType = 'datetime-local'
            break
        case 'DateTimeLocal':
            inputType = 'datetime-local'
            break
        case 'Boolean':
            inputType = 'checkbox'
            break
        case 'String':
            inputType = 'text'
            break
        default:
            inputType = 'text'
            break
    }
    return inputType
}

function renderEntity(entity, modelData, onInput, onCheckbox, saveObject) {
    if (entity === null) return

    let columns = modelData.columns;
    columns.sort((a, b) => a.index - b.index)

    let inputs = columns.map((column, index) => {
        if (index === 0) return null

        let inputType = getFieldType(column)

        if (inputType === 'checkbox') {
            return <div key={index} className="admin-dashboard-main-entity-checkbox">
                <FormControlLabel control={
                    <Checkbox checked={entity.entity[column.name]}
                              onClick={(e) => onCheckbox(e, column.name)}/>
                } label={Utils.titleString(column.name)}/>
            </div>
        } else {
            return <div key={index} className="admin-dashboard-main-entity-input">
                <TextField id={column.name}
                           label={Utils.titleString(column.name)}
                           variant="outlined"
                           type={inputType}
                           value={entity.entity[column.name]}
                           onInput={(e) => onInput(e, column.name)}/>
            </div>
        }
    })
    return <div className="admin-dashboard-main-entity">
        <div className="admin-dashboard-main-entity-inputs">
            {inputs.map(input => input)}
        </div>
        <Button classes={{root: 'admin-dashboard-main-save-button'}}
                variant="outlined" onClick={saveObject}>
            Добавить
        </Button>
    </div>
}

class AdminPanelModelsEntityAdd extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            title: '',

            page: props.page, models: [], isMenuOpen: false, showColumns: false,

            currentModel: null,
            modelData: {columns: []},
            entity: {entity: {}},

            fieldValue: '',
        }

        this.setTitle = (title) => {
            if (this.state.title !== title) this.setState({title: title})
        }

        this.showFieldsSwitch = generateSwitch('Показать поля', this.state.showColumns, () => {
            this.setState({showColumns: !this.state.showColumns})
        })

        this.setMenu = (status) => () => {
            this.setState({isMenuOpen: status});
        };

        this.openModel = (modelName) => {
            let model = null;
            this.state.models.forEach(tModel => {
                if (tModel.name === modelName) {
                    model = tModel
                }
            })
            window.open(`/admin/panel/models/${model.table_name}/`, '_blank')
        }

        this.findModel = () => {
            let pathname = window.location.pathname
            let foundModel = null
            this.state.models.forEach(model => {
                if (pathname.includes(model.table_name)) {
                    foundModel = model
                }
            })
            this.setState({currentModel: foundModel})
            this.setTitle(`${foundModel.name} ${this.state.fieldValue}`)

            API.adminInternalModel(foundModel.table_name, true)
                .then(data => {
                    this.setState({modelData: data})
                })
        }

        this.openEntityEdit = (fieldValue) => {
            window.location = `/admin/panel/models/${this.state.currentModel.table_name}/entity/${fieldValue}/`;
        }

        this.openMainPage = () => {
            window.location = `/admin/panel/models/${this.state.currentModel.table_name}/`;
        }

        this.onInput = (e, columnName) => {
            let entity = this.state.entity
            entity.entity[columnName] = e.target.value
            this.setState({entity: entity})
        }

        this.onCheckbox = (e, columnName) => {
            let entity = this.state.entity
            entity.entity[columnName] = !entity.entity[columnName]
            this.setState({entity: entity})
        }

        this.saveObject = () => {
            API.adminInternalModelEntityAdd(this.state.currentModel.table_name, this.state.entity.entity)
                .then(data => {
                    // let column = data.columns[0]
                    // this.openEntityEdit(data.entity[column])
                    this.openMainPage()
                })
        }

        this.adminTemplate = this.adminTemplate.bind(this);
    }

    componentDidMount() {
        let pathname = window.location.pathname
        let fieldValue = pathname.split('/entity/')[1].replace('/', '')
        this.setState({fieldValue: fieldValue})

        API.adminInternal()
            .then(data => {
                this.setState({
                    models: data.data
                }, () => {
                    this.findModel()
                })
            })
    }

    adminTemplate(content) {
        return <div className="customer-dashboard-container">
            <div className="customer-dashboard-menu">
                <IconButton classes={{root: 'customer-open-menu'}} aria-label="customer-open-menu"
                            onClick={this.setMenu(true)}>
                    <MenuOutlinedIcon/>
                </IconButton>
                <Drawer open={this.state.isMenuOpen} onClose={this.setMenu(false)}>
                    {renderMenu(this.setMenu)}
                </Drawer>
            </div>
            <div className="admin-dashboard-content admin-models-dashboard-content">
                <div className="admin-dashboard-content-internal">
                    <Button classes={{root: 'admin-dashboard-main-back-button'}}
                            variant="text" onClick={this.openMainPage}>
                        Вернуться
                    </Button>
                    <h1>{this.state.title}</h1>
                    {content}
                </div>
            </div>
        </div>
    }

    render() {
        let content = null;

        if (this.state.modelData) {
            content = <div className="admin-dashboard-models-main-objects-container">
                <div className="admin-dashboard-main-entities-container">
                    {renderEntity(this.state.entity, this.state.modelData, this.onInput, this.onCheckbox, this.saveObject)}
                </div>
            </div>
        }

        return this.adminTemplate(content)
    }
}

export default AdminPanelModelsEntityAdd;




