import React, {useEffect, useState} from 'react';
import cl from './promo-landing.module.css';
import LandingMain from './components/landing-main/landing-main.component';
import LandingBody from './components/landing-body/landing-body.component';
import LandingFaq from './components/landing-faq/landing-faq.component';
import LandingHeader from './components/landing-header/landing-header.component';
import FixedActions from './components/fixed-actions/fixed-actions.component';
import Utils from '../../utils';
import {Dialog, Slider, TextField} from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ConnectRequestService from './services/connect-request.service';

const MARKS = [
  {
    value: 0,
    label: '1',
  },
  {
    value: 20,
    label: '2',
  },
  {
    value: 40,
    label: '3',
  },
  {
    value: 60,
    label: '4',
  },
  {
    value: 80,
    label: '5',
  },
  {
    value: 100,
    label: '5+',
  },
];

function PromoLanding() {
  const [isOpen, setOpen] = useState(false);
  const [sumByPeriod, setSumByPeriod] = useState([]);
  const [selectedPeriod, setSelectedPeriod] = useState(0);

  const [companyName, setCompanyName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [period, setPeriod] = useState(0);

  const onCloseDialog = () => setOpen(false);
  const onOpenDialog = () => setOpen(true);

  const sendRequest = (params) => {
    ConnectRequestService.onSendRequest(params).then(() => {
      onCloseDialog();
    });
  };

  useEffect(() => {
    switch (selectedPeriod) {
      case 0:
        setPeriod(1);
        setSumByPeriod(['5', '840']);
        break;

      case 20:
        setPeriod(2);
        setSumByPeriod(['5', '160']);
        break;

      case 40:
        setPeriod(3);
        setSumByPeriod(['4', '800']);
        break;

      case 60:
        setPeriod(4);
        setSumByPeriod(['4', '400']);
        break;

      case 80:
        setPeriod(5);
        setSumByPeriod(['4', '100']);
        break;

      case 100:
        setPeriod(6);
        setSumByPeriod(['3', '900']);
        break;

      default:
        setPeriod(0);
        setSumByPeriod([]);
        break;
    }
  }, [selectedPeriod]);

  window.addEventListener('scroll', function () {
    const element = this.document.getElementById('actions');
    const scrollYValueToAction = Utils.isDeviceMobile() ? 400 : 700;

    return this.window.scrollY > scrollYValueToAction
      ? (element.style.opacity = '1')
      : (element.style.opacity = '0');
  });

  return (
    <div className={cl.promoPageContentComposition}>
      <div className={cl.header}>
        <LandingHeader />
      </div>

      <LandingMain openDialog={onOpenDialog} />

      <div id='actions' className={cl.actions}>
        <FixedActions openDialog={onOpenDialog} />
      </div>

      <LandingBody />
      <LandingFaq />

      <Dialog
        maxWidth={'sm'}
        fullWidth={false}
        scroll={Utils.isDeviceMobile() ? 'paper' : 'body'}
        classes={{root: 'modal-styles-root'}}
        onClose={onCloseDialog}
        open={isOpen}
      >
        <div className={cl.modal}>
          <div className={cl.modalHeader}>
            <span>Мы&nbsp;вам позвоним</span>

            <CloseRoundedIcon
              style={{cursor: 'pointer'}}
              onClick={() => onCloseDialog()}
            />
          </div>

          <div className={cl.modalBody}>
            <div className={cl.form}>
              <span className={cl.formDescription}>
                Расскажите, кто&nbsp;вы, и&nbsp;укажите контакт, куда
                мы&nbsp;можем написать для продолжения диалога&nbsp;&mdash;
                телефон, почту
              </span>

              <TextField
                label='Название компании, сети, заведения'
                variant='outlined'
                type='text'
                value={companyName}
                onInput={(e) => setCompanyName(e.target.value)}
              />

              <TextField
                label='Любой контакт'
                variant='outlined'
                type='text'
                value={phoneNumber}
                onInput={(e) => setPhoneNumber(e.target.value)}
              />
            </div>

            {/*<div className={cl.calculator}>*/}
            {/*  <span className={cl.description}>*/}
            {/*    Расскажите, сколько у&nbsp;вас точек и&nbsp;получите примерную*/}
            {/*    цену за&nbsp;одну*/}
            {/*  </span>*/}

            {/*  <Slider*/}
            {/*    value={selectedPeriod}*/}
            {/*    onChange={(e) => setSelectedPeriod(e.target.value)}*/}
            {/*    defaultValue={20}*/}
            {/*    step={20}*/}
            {/*    marks={MARKS}*/}
            {/*  />*/}

            {/*  <div className={cl.resultContainer}>*/}
            {/*    <span className={cl.resultDescription}>*/}
            {/*      Примерная стоимость <br />*/}
            {/*      за&nbsp;одну точку в&nbsp;месяц*/}
            {/*    </span>*/}

            {/*    <span className={cl.sum}>*/}
            {/*      От&nbsp;*/}
            {/*      <span className={cl.thousand}>{sumByPeriod[0]}&nbsp;</span>*/}
            {/*      <span className={cl.number}>{sumByPeriod[1]}&nbsp;</span>*/}
            {/*      <span className={cl.r}>₽</span>*/}
            {/*    </span>*/}
            {/*  </div>*/}
            {/*</div>*/}

            <div className={cl.calculator}>
              <span className={cl.description}>
                Расскажите, сколько у&nbsp;вас точек &nbsp;
              </span>

              <Slider
                value={selectedPeriod}
                onChange={(e) => setSelectedPeriod(e.target.value)}
                defaultValue={20}
                step={20}
                marks={MARKS}
              />
            </div>

            <button
              className={cl.onSubmit}
              onClick={() =>
                sendRequest({
                  storeName: companyName,
                  contact: phoneNumber,
                  period,
                })
              }
            >
              Свяжитесь со&nbsp;мной
            </button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

export default PromoLanding;
