import cl from './store.module.css';
import React from 'react';
import {ReactComponent as CheckedIcon} from '../../../../../images/customIconChackedWhite.svg';

function Store({store, isSelected, onSelectStore}) {
  return (
    <button className={cl.action} onClick={() => onSelectStore(store.id)}>
      <div className={cl.storeNameAndAddress}>
        <span className={cl.storeName}>{store.name}</span>
        <span className={cl.storeAddress}>{store.address}</span>
      </div>

      {isSelected && (
        <div className={cl.selectedStore}>
          <CheckedIcon />
        </div>
      )}
    </button>
  );
}

export default Store;
