import React from "react";
import Auth from "./auth";
import API from "../api";

import '../style/app.css';

import Storage from '../utils/storage';
import {withCookies} from 'react-cookie';
import {CircularProgress} from "@mui/material";
import ServerErrorPage from "./serverErrorPage";
import CustomerDashboard from "./customerDashboard";
import Utils from "./utils";
import AdminPanel from "./adminPanel";
import AdminPanelModels from "./adminPanelModels";
import AdminPanelModelsEntity from "./adminPanelModelsEntity";
import AdminPanelModelsEntityAdd from "./adminPanelModelsEntityAdd";
import StoreChannel from "./storeChannel";
import ConnectPage from "./connectPage";
import ConnectDevicePage from "./connectDevicePage";
import AccountPage from "./accountPage";
import SupportPage from "./supportPage";
import TestPage from "./testElement";
import DocumentsPublicOffer from "./documentsPublicOffer";
import PricePage from "./components/price-page/price-page.component";
import PromoLanding from "./components/promo-landing/promo-landing.component";
import EditorComponent from "./editor";

function clearCookies() {
    const cookies = document.cookie.split(";");

    cookies.forEach(cookie => {
        const eqPos = cookie.indexOf("=");
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
    });

    document.cookie.split(";").forEach(function (c) {
        document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });
}

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            token: this.props.cookies.get('token'),
            authorize: null,
            serverError: false,
            errorElement: {},
            platform: this.props.cookies.get('platform'),
            is_tv_app: this.props.cookies.get('platform') === 'PubHubTvApp'
        };

        this.ref = React.createRef()

        this.authorize = (email, password, callback) => {
            API.csrf().then(data => {
                Storage.set('csrf', data.csrf_token)
                API.login(email, password)
                    .then(data => {
                        const jwtToken = data.token;
                        this.props.cookies.set('token', jwtToken, {path: '/'})
                        Storage.set('token', jwtToken)
                        callback(true)
                        API.profile()
                            .then(data => {
                                Storage.set('user', data)
                                this.setState({token: jwtToken, authorize: true})
                            })
                            .catch(e => {
                                this.setState({authorize: false})
                            })
                    })
                    .catch(e => callback(false))
            })
                .catch(e => console.error(e))
        }

        this.logout = () => {
            clearCookies()

            Storage.delete('token')
            // window.location.reload()
            window.location.href = '/'
        }

        window.onerror = this.handleThrownErrors.bind(this);
        this.renderInternal = this.renderInternal.bind(this);
        this.showErrorMessage = this.showErrorMessage.bind(this);
    }

    showErrorMessage(title, message, error) {
        this.setState({
            errorElement: {
                title: title,
                message: message,
                errorMessage: error ? error.message : null,
                errorStack: error ? error.stack : null
            }
        })
    }

    handleThrownErrors(error) {
        console.error(error)
        this.showErrorMessage(
            'Error',
            'Execute code error',
            error
        )
    }

    componentDidMount() {
        API.csrf().then(data => {
            Storage.set('csrf', data.csrf_token)

            API.profile()
                .then(data => {
                    Storage.set('user', data)
                    this.setState({authorize: true})
                })
                .catch(e => {
                    this.setState({authorize: false})
                })
        })
            .catch(e => this.showErrorMessage(
                'Error',
                'API request error',
                e
            ))
    }

    renderInternal() {
        if (this.state.errorElement.length) return <div className="base-error-container">
            <div className="base-error-title">{this.state.errorElement.title}</div>
            <div className="base-error-message">{this.state.errorElement.message}</div>
            <div className="base-error-obj-message">{this.state.errorElement.errorMessage}</div>
            <div className="base-error-obj-stack">{this.state.errorElement.errorStack}</div>
        </div>

        const pathname = window.location.pathname;

        if (this.state.token !== Storage.get('token')) {
            Storage.set('token', this.state.token)
        }

        let user = Storage.get('user', {})
        const isAdmin = user.is_admin;

        if (pathname === '/tttt' || pathname === '/tttt/') {
            Utils.setPageTitle('Таблица')
            return <TestPage logout={this.logout}/>
        }

        if (pathname === '/documents/oferta' || pathname === '/documents/oferta/') {
            Utils.setPageTitle('Оферта')
            return <DocumentsPublicOffer
                title="Оферта"
                url="/assets/offer_v1.pdf"
                logout={this.logout}/>
        }

        if (pathname === '/documents/privacy/' || pathname === '/documents/privacy/') {
            Utils.setPageTitle('Политика конфидециальности')
            return <DocumentsPublicOffer
                title="Политика конфидециальности"
                url="/assets/privacy_v1.pdf"
                logout={this.logout}/>
        }

        if (pathname === '/connect/' || pathname === '/connect') return <ConnectPage/>
        else if (pathname.startsWith('/store/window/')) return <StoreChannel is_tv_app={this.state.is_tv_app}/>

        if (this.state.serverError) {
            Utils.setPageTitle('Ошибка')
            return <ServerErrorPage/>

        } else if (this.state.authorize === null) {
            return <div className="auth-empty-container"><CircularProgress/></div>

        } else if (pathname === '/' || pathname === '') {
            Utils.setPageTitle('Меню-борды для пивных магазинов')

            return <PromoLanding/>
        } else if (pathname === '/login' || pathname === '/login/') {
            Utils.setPageTitle('Авторизация')

            if (this.state.authorize) window.location.href = '/cabinet'

            return <Auth authorize={this.authorize}/>

        } else if (pathname === '/price' || pathname === '/price/') {
            // Utils.setPageTitle('Прайс');
            // return <PricePage/>;
            Utils.setPageTitle('Ошибка')
            return <ServerErrorPage/>

        } else if (!this.state.authorize) {
            Utils.setPageTitle('Авторизация')
            return <Auth authorize={this.authorize}/>

        } else if (pathname === '/cabinet' || pathname === '/cabinet/') {
            Utils.setPageTitle('Управление')
            return <CustomerDashboard logout={this.logout}/>

        } else if (pathname === '/account' || pathname === '/account/') {
            Utils.setPageTitle('Аккаунт и подписка')
            return <AccountPage logout={this.logout}/>

        } else if (pathname === '/editor' || pathname === '/editor/') {
            Utils.setPageTitle('Конструктор отображения')
            return <EditorComponent logout={this.logout}/>

        } else if (pathname === '/support' || pathname === '/support/') {
            Utils.setPageTitle('Поддержка')
            return <SupportPage logout={this.logout}/>

        } else if (pathname === '/logout' || pathname === '/logout/') {
            Utils.setPageTitle('Выход...')
            this.logout()
            return <div>Выход...</div>

        } else if (pathname === '/connect/device/') {
            return <ConnectDevicePage/>

        } else if (pathname.startsWith('/admin/panel/models/') && pathname.includes('entity') && pathname.includes('create') && isAdmin) {
            return <AdminPanelModelsEntityAdd/>

        } else if (pathname.startsWith('/admin/panel/models/') && pathname.includes('entity') && isAdmin) {
            return <AdminPanelModelsEntity/>

        } else if (pathname.startsWith('/admin/panel/models/') && isAdmin) {
            Utils.setPageTitle('Models')
            return <AdminPanelModels/>

        } else if (pathname.startsWith('/admin/panel/') && isAdmin) {
            Utils.setPageTitle('Admin Panel')
            return <AdminPanel/>

        } else if (pathname === '/dildo' || pathname === '/dildo/') {
            Utils.setPageTitle('Меню-борды для пивных магазинов')

            return <PromoLanding/>
        }

        return <div className="not-found">
            <h2>Произошла ошибка</h2>
            <a href="/login">Вернуться на главную</a>
        </div>
    }

    render() {
        try {
            return <div className="app" ref={this.ref}>
                {this.renderInternal()}
            </div>
        } catch (error) {
            this.handleThrownErrors(error)
        }
    }
}

export default withCookies(App);
