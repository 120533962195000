import React, { useState } from "react";
import { IconButton, InputAdornment, OutlinedInput } from "@mui/material";
import "./change-password.component.css";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import PasswordService from "./services/password.service";
import Storage from "../../../../../utils/storage";
import { useCookies } from "react-cookie";

function ChangePasswordComponent() {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [isError, setError] = useState();
  const [passwordChanged, setPasswordChanged] = useState(false);

  const [cookies, setCookie, removeCookie] = useCookies(["token"]);

  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);

  const [showOldPassword, setShowOldPassword] = React.useState(false);
  const handleClickShowOldPassword = () => setShowOldPassword((show) => !show);

  function onEditPassword() {
    const query = { current_password: oldPassword, new_password: newPassword };

    PasswordService.setNewPassword(query)
      .then((res) => {
        setCookie("token", res.token, { path: "/" });
        Storage.set("token", res.token);

        res.status === true
          ? setPasswordChanged(true)
          : setPasswordChanged(false);

        return setError(!res.status);
      })
      .catch(() => {
        setPasswordChanged(false);
        setError(true);
      });

    setOldPassword("");
    setNewPassword("");
  }

  return (
    <div className="card-wrapper">
      <div className="card-name">Сменить пароль</div>
      <OutlinedInput
        placeholder="Старый пароль"
        style={
          window.innerWidth > 1024
            ? { borderRadius: "16px", width: "70%" }
            : { borderRadius: "16px", width: "100%" }
        }
        type={showOldPassword ? "text" : "password"}
        value={oldPassword}
        onChange={(e) => setOldPassword(e.target.value)}
        endAdornment={
          <InputAdornment position="end">
            <IconButton onClick={() => handleClickShowOldPassword()} edge="end">
              {showOldPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
      />

      <OutlinedInput
        placeholder="Новый пароль"
        style={
          window.innerWidth > 1024
            ? { borderRadius: "16px", width: "70%" }
            : { borderRadius: "16px", width: "100%" }
        }
        type={showNewPassword ? "text" : "password"}
        value={newPassword}
        onChange={(e) => setNewPassword(e.target.value)}
        endAdornment={
          <InputAdornment position="end">
            <IconButton onClick={() => handleClickShowNewPassword()} edge="end">
              {showNewPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
      />
      <div className="action-password-wrapper">
        <div>
          <button
            disabled={oldPassword.length === 0 || newPassword.length === 0}
            onClick={() => onEditPassword()}
            className="action-save-password"
          >
            Сохранить
          </button>
        </div>

        {isError ? (
          <div className="password-change-error">
            Некорректно введен старый пароль
          </div>
        ) : null}

        {passwordChanged ? (
          <div className="password-changed">Новый пароль установлен</div>
        ) : null}
      </div>
    </div>
  );
}

export default ChangePasswordComponent;
