import React from 'react';
import API from '../api';
import '../style/storeChannel.css';
import {CircularProgress, Skeleton} from '@mui/material';
import NoSleep from 'nosleep.js';
import NoSleepEngine from './components/noSleepEngine';
import TakeScreenshot from './components/screenshotEngine';
import {getTs, sleep, timePassed} from '../utils';
import {SERVER_URL} from '../config';
import AsideCardsWrapperComponent from './components/aside-cards-wrapper/aside-cards-wrapper.component';
import BeerItemTable from './components/beer-item-table/beer-item-table.component';
import Storage from '../utils/storage';
import AsideCard from './components/aside-cards-wrapper/components/aside-card/aside-card.component';
import PubhubPromo from './components/pubhub-promo/pubhub-promo.component';
import {ReactComponent as CustomPubHubLogoForTableView} from '../images/customPubHubLogoForTableView.svg';
import BannerItemTable from "./components/banner-item-table/banner-item-table.component";
import {rangeList} from "./utils";


function renderItem(index, menuItem, crane) {
  let beerTypes = {
    dark: 'store-channel-container-menu-item-dark-beer',
    light: 'store-channel-container-menu-item-light-beer',
    red: 'store-channel-container-menu-item-red-beer',
    sweet: 'store-channel-container-menu-item-sweet-beer',
  };

  let beerLabels = {
    dark: 'тёмное',
    light: 'светлое',
    sweet: 'сладкое',
    red: 'красное',
  };
  let beerType = beerTypes[menuItem.beer_color];
  let beerLabel = beerLabels[menuItem.beer_color];

  let priceClassList = ['store-channel-container-menu-item-price-container'];
  priceClassList.push(beerType);
  priceClassList = priceClassList.join(' ');

  let tags = [
    {color: menuItem.tag1_color, name: menuItem.tag1_name},
    {
      color: menuItem.tag2_color,
      name: menuItem.tag2_name,
    },
    {color: menuItem.tag3_color, name: menuItem.tag3_name},
    {color: menuItem.tag4_color, name: menuItem.tag4_name},
  ];

  tags = tags.filter((tag) => tag.name);

  let classList = ['store-channel-container-menu-item'];
  if (crane.is_product_end)
    classList.push('store-channel-container-menu-item-ended');
  let className = classList.join(' ');

  return (
    <div key={index} className={className}>
      <div className='store-channel-container-menu-item-number store-channel-container-menu-item-base-card'>
        <p>{crane.number}</p>
        <span>кран</span>
      </div>
      <div className='store-channel-container-menu-item-tags'>
        {Array.from(tags).map((tag, index) => {
          return (
            <div
              key={index}
              className='store-channel-container-menu-item-tag'
              style={{backgroundColor: tag.color}}
            >
              {tag.name}
            </div>
          );
        })}
      </div>
      <div className='store-channel-container-menu-item-avatar'>
        <img
          alt={menuItem.name}
          src={menuItem.image && menuItem.image.url ? SERVER_URL + menuItem.image.url.split('?')[0] : null}
        />
      </div>
      <div
        className={
          crane.is_product_end
            ? 'store-channel-bottom-container store-channel-bottom-container-ended'
            : 'store-channel-bottom-container'
        }
      >
        <div className='store-channel-container-menu-item-alcohol store-channel-container-menu-item-base-card'>
          <p>{menuItem.alcohol_degrees}</p>
          <span>алкоголь</span>
        </div>
        {!crane.is_product_end ? (
          <div className={priceClassList}>
            <div className='store-channel-container-menu-item-color'>
              {beerLabel}
            </div>
            <div className='store-channel-container-menu-item-price'>
              <p>{menuItem.price} ₽</p>
              <span>за литр</span>
            </div>
          </div>
        ) : null}
        <div className='store-channel-container-menu-item-density store-channel-container-menu-item-base-card'>
          <p>{menuItem.alcohol_density}</p>
          <span>плотность</span>
        </div>
      </div>
      <div
        className={
          crane.is_product_end
            ? 'store-channel-bottom-name store-channel-bottom-name-ended'
            : 'store-channel-bottom-name'
        }
      >
        <div className='store-channel-bottom-container-name'>
          {menuItem.name}
        </div>
      </div>
    </div>
  );
}

class StoreChannel extends React.Component {
  constructor(props) {
    super(props);

    const updateDelay = 15; // seconds
    const updateScreenshotDelay = 5 * 60; // seconds
    const firstScreenshotDelay = 3; // seconds

    this.state = {
      windowNumber: 1,
      webKey: null,
      timerId: null,
      timerScreenshotId: null,
      perPage: 15,
      constPerPage: 15,

      needFullData: true,

      owner: {
        categories: [],
        menu_items: [],
        store: {
          last_update: '',
          table_scale: 1,
        },
        total_categories: [],
        cranes: [],
        screens: [],
        system_info: {
          client_id: Storage.get('WebClientID', '1.00.00.00'),
        },
      },
      background: null,
      noSleepEngine: this.props.is_tv_app ? <div></div> : null,
      noSleepLoading: false,
      readyForStart: false,

      updateDelay: updateDelay * 1000,
      updateScreenshotDelay: updateScreenshotDelay * 1000,
      firstScreenshotDelay: firstScreenshotDelay * 1000,
    };

    this.noSleep = new NoSleep();

    this.skeletonStartCount = 11;
    this.skeletonCount =
      this.skeletonStartCount +
      Math.ceil(Math.random() * (15 - this.skeletonStartCount));
    this.skeletons = rangeList(this.skeletonCount).map((index) => {
      return (
        <Skeleton key={index} variant='circular' sx={{bgcolor: '#00000073'}} />
      );
    });

    this.initBackground = () => {
      let background = null;

      const currentScreen = this.state.owner.screens.length
      ? this.state.owner.screens.find(
          (screen) => screen.number === this.state.windowNumber
        )
      : {positions: [], additional: []};

      if (currentScreen.background_type === 'color') {
        let styles = {
          backgroundColor: currentScreen.background_color,
        };
        background = <div className='background-color' style={styles} />;
      } else if (currentScreen.background_type === 'image') {
        let styles = {
          background: `url(${
            SERVER_URL + currentScreen.background_image.url.split('?')[0]
          }) no-repeat center center`,
        };
        let imageClassList = ['background-image'];
        if (currentScreen.background_blur)
          imageClassList.push('background-image-blur');
        imageClassList = imageClassList.join(' ');
        background = (
          <div className={imageClassList} style={styles}>
            <img
              src={SERVER_URL + currentScreen.background_image.url.split('?')[0]}
              alt='bg'
            />
          </div>
        );
      }
      this.setState({background: background, readyForStart: true});
    };

    this.checkUpdates = () => {
      if (this.state.webKey !== null) {
        API.storeChannel(this.state.webKey, this.state.needFullData)
          .then((data) => {
            if (
              data.system_info.client_id !==
                this.state.owner.system_info.client_id &&
              this.props.is_tv_app
            ) {
              window.location.reload();
            }

            if (data.full_data) {
              let menuItemsIds = {};
              data.menu_items.forEach((menuItem) => {
                menuItemsIds[menuItem.id] = menuItem;
              });

              this.setState(
                {
                  updateDelay: 15000,
                  owner: data,
                  menuItemsIds: menuItemsIds,
                  needFullData:
                    this.state.owner.store.last_update !==
                    data.store.last_update,
                },
                () => this.initBackground()
              );
            } else {
              this.setState(
                {
                  updateDelay: 15000,
                  needFullData:
                    this.state.owner.store.last_update !==
                    data.store.last_update,
                },
                () => this.initBackground()
              );
            }

            Storage.set('WebClientID', data.system_info.client_id);
          })
          .catch((e) => {
            console.error(e);
            this.setState({
              updateDelay: Math.min(this.state.updateDelay * 2, 60000),
            });
          })
          .finally(() => {
            setTimeout(this.checkUpdates, this.state.updateDelay);
          });
      }
    };

    this.sendScreenshot = async () => {
      try {
        document.getElementById('take-screenshot').click();
        let timeFlag = getTs();

        if (this.state.webKey !== null) {
          let screenshot = document.getElementById('screenshot-image');
          while (!screenshot) {
            screenshot = document.getElementById('screenshot-image');

            if (timePassed(timeFlag) > 60) {
              return;
            }

            await sleep(500);
          }

          screenshot = document.getElementById('screenshot-image');
          if (screenshot && screenshot.getAttribute('src')) {
            API.sendScreenshot({
              webKey: this.state.webKey,
              windowNumber: this.state.windowNumber,
              screenshot: screenshot.getAttribute('src'),
            })
              .then((data) => console.error(data))
              .catch((e) => console.error(e));
          }
        }
      } catch (e) {
        console.error(e);
      }
    };

    this.startInterval = () => {
      if (this.state.timerId === null) {
        let timerId = setTimeout(this.checkUpdates, this.state.updateDelay);
        this.setState({timerId: timerId});
      }
      // if (this.state.timerScreenshotId === null) {
      //   let timerId = setInterval(
      //     this.sendScreenshot,
      //     this.state.updateScreenshotDelay
      //   );
      //   this.setState({timerScreenshotId: timerId});
      // }
    };

    this.startNoSleep = () => {
      this.setState({noSleepLoading: true});
      setTimeout(() => {
        this.setState({noSleepEngine: <NoSleepEngine />});
      }, 1000);
    };
  }

  componentDidMount() {
    let parts = window.location.pathname.split('/channel/');
    let windowNumber = parts[0].split('/');
    windowNumber = parseInt(windowNumber[windowNumber.length - 1]);
    let webKey = parts[1]
      .replace('/', '')
      .replace('/', '')
      .replace('/', '')
      .replace('/', '')
      .replace('/', '')
      .replace('/', '')
      .replace('/', '')
      .replace('/', '');
    this.setState(
      {windowNumber: windowNumber, webKey: webKey},
      this.checkUpdates
    );
    this.startInterval();
  }

  render() {
    const cranes = this.state.owner.cranes.filter(
      (crane) => crane.menu_item_id && crane.menu_item_id !== 'disable'
    );
    let menuItemsStore = this.state.owner.menu_items;
    let bannersStore = this.state.owner.banners;
    const currentScreen = this.state.owner.screens.length
      ? this.state.owner.screens.find(
          (screen) => screen.number === this.state.windowNumber
        )
      : {positions: [], additional: []};

    const screenItemsCount =
      currentScreen?.positions.filter((val) => val).length ?? 0;

    const asideItems =
      currentScreen !== undefined
        ? currentScreen.additional.filter(item => item)
            .map((asideItem) => {
              const item =
                menuItemsStore.find((item) => item.id === asideItem.id) ?? null;

              const crane =
                cranes.find((item) => item.menu_item_id === asideItem.id) ?? null;

              return (
                item === null || {
                  menuItem: this.state.menuItemsIds[item.id],
                  crane: crane || {},
                }
              );
            })
            .filter((item) => item !== null && item.menuItem !== undefined) ??
          []
        : [];

    let classList = [
      'store-channel-container',
      currentScreen.display_type === 'cards'
        ? 'store-channel-container-cards'
        : '',
      currentScreen.display_type === 'table'
        ? 'store-channel-container-table'
        : '',
      currentScreen.own_content
        ? 'store-channel-container-own-content' : ''
    ];

    let bannerIds = new Set()

    if (currentScreen.own_content) {
      if (currentScreen.own_content_type === 'image_url') {
        return (
            <div className={classList.join(' ')}>
              <img src={currentScreen.own_content_image_url} alt="own content"/>
            </div>
        )
      } else if (currentScreen.own_content_type === 'video_url') {
        return (
            <div className={classList.join(' ')}>
              <video muted autoPlay loop playsInline>
                  <source src={currentScreen.own_content_video_url} type='video/mp4'/>
                  Ваш браузер не поддерживает видео.
              </video>
            </div>
        )
      } else if (currentScreen.own_content_type === 'image') {
        return (
            <div className={classList.join(' ')}>
              <img src={SERVER_URL + currentScreen.own_content_image.url} alt="own content"/>
            </div>
        )
      }
    }

    return (
      <div className={classList.join(' ')}>
        <TakeScreenshot />

        {this.state.background}
        {this.state.noSleepEngine ? (
          this.state.noSleepEngine
        ) : (
          <div className='store-channel-start-button-container'>
            <div className='store-channel-start-button-internal'>
              <div className='store-channel-start-button-title'>
                Экран {this.state.windowNumber}
              </div>
              <div className='store-channel-start-button-label'>
                {this.state.readyForStart ? 'Готово к запуску' : 'Загрузка...'}
              </div>
              <div className='store-channel-start-buttons-element'>
                {this.state.noSleepLoading || !this.state.readyForStart ? (
                  <div
                    className='store-channel-start-button'
                    onClick={this.startNoSleep}
                  >
                    <CircularProgress />
                  </div>
                ) : (
                  <>
                    <div
                      className='store-channel-start-button'
                      onClick={this.startNoSleep}
                    >
                      Запустить
                    </div>
                    <div
                      className='store-channel-start-button store-channel-start-button-light'
                      onClick={() =>
                        this.setState({
                          noSleepLoading: false,
                          noSleepEngine: <div></div>,
                        })
                      }
                    >
                      Облегченный режим
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        )}
        {currentScreen.display_type === 'cards' ? (
          <div className='store-channel-content'>
            <div className='promo'>
              <PubhubPromo />
            </div>

            {currentScreen.positions.filter(item => item).map((position, index) => {
              const crane =
                cranes.find((item) => item.menu_item_id === position.id) ?? null;
              const menuItem =
                menuItemsStore.find((item) => item.id === position.id) ?? null;

              return menuItem !== null ? (
                // ? renderItem(index, menuItem, crane !== null ? crane : {})
                <AsideCard
                  key={menuItem.id}
                  menuItem={menuItem}
                  crane={crane || {}}
                />
              ) : null;
            })}
          </div>
        ) : (
          <div
            className='store-channel-content-table'
            style={{transform: `scale(${1 - currentScreen.table_scale / 100})`}}>
            <div className='store-channel-content-table-wrapepr'
                 style={{alignItems: currentScreen.vertical_alignment === 'up' ? 'flex-start': 'center'}}
            >
              <div className='promoTable'>
                <PubhubPromo />
              </div>
              <div className='store-channel-content-table-composition'>
                {currentScreen.positions.filter(item => item).map((position, index) => {
                  if (position.type === 'menuitem') {
                    const crane =
                      cranes.find((item) => item.menu_item_id === position.id) ??
                      null;
                    const menuItem =
                      menuItemsStore.find((item) => item.id === position.id) ?? null;

                    return menuItem !== null ? (
                      <BeerItemTable
                        key={`menuitem-${menuItem.id}`}
                        index={index}
                        menuItem={menuItem}
                        crane={crane !== null ? crane : {}}
                        store={this.state.owner.store}
                        isBigItems={currentScreen.dynamic_cell_size && screenItemsCount < 16}
                      />
                    ) : null;
                  }

                  const banner =
                      bannersStore.find((item) => item.id === position.id) ?? null;

                  if (bannerIds.has(banner.id)) return null
                  bannerIds.add(banner.id)

                  return <BannerItemTable
                        key={`banner-${banner.id}`}
                        index={index}
                        banner={banner}
                        store={this.state.owner.store}
                  />
                })}
              </div>

              {asideItems.length > 0 ? (
                <AsideCardsWrapperComponent
                  items={asideItems.map(({crane, menuItem}) => {
                    return {menuItem, crane};
                  })}
                />
              ) : null}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default StoreChannel;
