import React from "react";

function ButtonComponent({className, label, onClick, bgColor, bgColorHover, textColor, textColorHover, href, target, disabled}) {
    let componentStyle = {}

    if (bgColor) componentStyle['--system-color'] = bgColor
    if (bgColorHover) componentStyle['--system-color-hover'] = bgColorHover
    if (textColor) componentStyle['--system-text-color'] = textColor
    if (textColorHover) componentStyle['--system-text-color-hover'] = textColorHover
    if (target === undefined) target = '_blank'

    if (href) return <a className={`button-component ${className ? className : ''}`}
                        style={componentStyle} onClick={disabled ? ()=>{} : onClick} href={disabled ? null : href} target={target} rel="noreferrer">{label}</a>

    return <div className={`button-component ${className ? className : ''}`} style={componentStyle} onClick={disabled ? ()=>{} : onClick}>
        {label}
    </div>
}

export default ButtonComponent;