import React from 'react';
import '../../../style/headerMenu.css';
import {ReactComponent as LogoIcon} from '../../../images/logo.svg';


function HeaderMenu({header, content, contentClass, subNotification}) {
    const pathName = window.location.pathname;

    const isMainPage = pathName === '/';
    const isAccountPage = pathName === '/account' || pathName === '/account/';

    return (
        <>
            <div className='HeaderMenu'>
                <div className='HeaderMenu-container'>
                    <a className='HeaderMenu-logo' href='/cabinet'>
                        <LogoIcon/>
                    </a>
                    <div className='HeaderMenu-elements'>{header}</div>
                </div>

                {subNotification && (
                    <div
                        className='notification-container'
                        style={{
                            backgroundColor: subNotification.color,
                        }}
                    >
                        <div className='notification-container-wrap'>
              <span
                  className='notification-body'
                  dangerouslySetInnerHTML={{__html: subNotification.text}}
              ></span>

                            {subNotification?.buttons?.length > 0 &&
                            isAccountPage !== true ? (
                                <div className='notification-container__actions'>
                                    {subNotification.buttons.map((button, index) => (
                                        <a
                                            className='notification-container__actions--button'
                                            key={index}
                                            href={button.url}
                                        >
                                            {button.text}
                                        </a>
                                    ))}
                                </div>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                )}
            </div>
            <div
                style={
                    !subNotification &&
                    isMainPage === true &&
                    subNotification?.blocked === true
                        ? {opacity: '50%', pointerEvents: 'none'}
                        : {}
                }
                className={contentClass}
            >
                {content}
            </div>
        </>
    );
}

export default HeaderMenu;
