import React, { useEffect, useState } from "react";
import "./requisites-info.component.css";
import AccountInfoService from "./services/account-info.service";

function RequestInfoComponent() {
  const [accountInfo, setAccountInfo] = useState(null);

  useEffect(() => {
    AccountInfoService.getCompanyInfo().then((res) =>
      setAccountInfo(res.company)
    );
  }, []);

  return accountInfo !== null ? (
    <div className="card-wrapper">
      <div className="card-name">Реквизиты</div>
      <div className="card-part">
        <div className="part-name">Тип организации</div>
        <div className="part-value">
          {accountInfo.type === "ip" ? "ИП" : "ООО"}
        </div>
      </div>
      <div className="card-part">
        <div className="part-name">Организация</div>
        <div className="part-value">{accountInfo.name}</div>
      </div>
      <div className="card-part">
        <div className="part-name">Юридический адрес</div>
        <div className="part-value">{accountInfo.legal_address}</div>
      </div>
      <div className="card-part">
        <div className="parts-wrapper">
          <div className="card-part">
            <div className="part-name">ИНН</div>
            <div className="part-value">{accountInfo.tax_code}</div>
          </div>

          <div className="card-part">
            <div className="part-name">Корреспондентский счёт</div>
            <div className="part-value">
              {accountInfo.correspondent_account}
            </div>
          </div>
        </div>
      </div>
      <div className="divider"></div>
      <div className="card-part">
        <div className="part-name">Название банка</div>
        <div className="part-value">{accountInfo.bank_name}</div>
      </div>
      <div className="card-part">
        <div className="parts-wrapper">
          <div className="card-part">
            <div className="part-name">БИК</div>
            <div className="part-value">{accountInfo.bic}</div>
          </div>
        </div>
      </div>
      <div className="card-part">
        <div className="parts-wrapper">
          <div className="card-part">
            <div className="part-name">РС</div>
            <div className="part-value">{accountInfo.payment_account}</div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
}

export default RequestInfoComponent;
