import Store from './components/store/store.component';
import cl from './select-store.module.css';
import React from 'react';

function SelectStore({
  stores,
  label,
  sublabel,
  setSelectedStore,
  selectedStoreId,
}) {
  return (
    stores && (
      <div className={cl.selectStoreWrapper}>
        <div className={cl.labelWrapper}>
          {label && <label className={cl.label}>{label}</label>}

          {sublabel && <span className={cl.sublabel}>{sublabel}</span>}
        </div>

        <div className={cl.storesWrapper}>
          {stores.map((store, index) => (
            <Store
              key={index}
              store={store}
              isSelected={selectedStoreId === store.id}
              onSelectStore={setSelectedStore}
            />
          ))}
        </div>
      </div>
    )
  );
}

export default SelectStore;
