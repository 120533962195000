import React from "react";
import classes from "./my-tab.module.css";

function MyTabComponent({ count, discount, active, onSelect, periodId }) {
  return (
    <>
      {active ? (
        <button
          onClick={() => onSelect(periodId)}
          className={classes.tabActive}
        >
          {discount > 0 ? (
            <div className={classes.periodAndDiscount}>
              <div className={classes.countActive}>{count}</div>
              <div className={classes.discount}>-{discount}%</div>
            </div>
          ) : (
            <div className={classes.countActive}>{count}</div>
          )}
        </button>
      ) : (
        <button onClick={() => onSelect(periodId)} className={classes.tab}>
          {discount > 0 ? (
            <div className={classes.periodAndDiscount}>
              <div className={classes.count}>{count}</div>
              <div className={classes.discount}>-{discount}%</div>
            </div>
          ) : (
            count
          )}
        </button>
      )}
    </>
  );
}

export default MyTabComponent;
