import React from "react";
import '../style/editor.css'
import {ReactComponent as LogoIcon} from "../images/logo.svg";
import {ReactComponent as BannerAddIcon} from "../images/bannerAdd.svg";
import {ReactComponent as TelegramBlackIcon} from "../images/telegramBlack.svg";
import {ReactComponent as TrashIcon} from "../images/trash.svg";
import {ReactComponent as EditPencilIcon} from "../images/editPencil.svg";
import {ReactComponent as NoMobileIcon} from "../images/noMobile.svg";
import ButtonComponent from "./components/buttonComponent";
import API from "../api";
import Storage from "../utils/storage";
import TextField from "@mui/material/TextField";
import {SERVER_URL} from "../config";
import {FormControl, IconButton, InputLabel, MenuItem, Select, Slider, Switch} from "@mui/material";
import MultiRadioButton from "./components/multiRadioButton";
import Button from "@mui/material/Button";
import * as PropTypes from "prop-types";
import {rangeList, safetyInput} from "./utils";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import Dialog from "@mui/material/Dialog";
import ImageUploader from "./components/image-uploader/image-uploader.component";
import MultiRadioColorsButton from "./components/multiRadioColorsButton";

function VisuallyHiddenInput(props) {
    return null;
}

VisuallyHiddenInput.propTypes = {
    onChange: PropTypes.func,
    name: PropTypes.string,
    id: PropTypes.string,
    type: PropTypes.string,
    accept: PropTypes.string
};

class EditorComponent extends React.Component {
    constructor(props) {
        super(props);

        //         tableScale: nextScreen.table_scale,
        // ownContent: nextScreen.own_content,
        // screenBackgroundColor: nextScreen.background_color,
        // screenBackgroundImage: nextScreen.background_image,
        // ownContentType: nextScreen.own_content_type,
        // ownContentImageUrl: nextScreen.own_content_image_url,
        // ownContentVideoUrl: nextScreen.own_content_video_url,
        // ownContentImage: nextScreen.own_content_image,

        this.state = {
            store: {},
            stores: [],
            contacts: {},
            waitLabel: null,
            isMenuOpen: false,
            owner: {
                store: {},
                menu_items: []
            },
            screens: [
                {
                    type: 'general',
                    number: 0,
                    name: 'Общее',
                    label: 'Все экраны',
                    display_type: 'table',
                    vertical_alignment: 'up',
                    dynamic_cell_size: 'enabled',
                    background_type: 'image',
                    background_color: '#000000',
                    table_scale: 0,
                    own_content: false,
                    background_image: {image: null},
                    own_content_type: 'image_url',
                    own_content_image_url: '',
                    own_content_video_url: '',
                    own_content_image: {image: null},
                },
            ],
            screenSelected: 0,

            autofillSort: '',
            autofillOrder: '',

            displayTypeValues: {
                table: 'Таблица',
                cards: 'Карточки',
            },
            displayType: 'table',

            verticalAlignmentValues: {
                up: 'По верхнему краю',
                center: 'По центру',
            },
            verticalAlignment: 'up',

            cellSizeValues: {
                enabled: 'Да',
                disabled: 'Нет',
            },
            cellSize: 'enabled',

            screenBackgroundValues: {
                image: 'Картинка',
                color: 'Цвет'
            },
            screenBackground: 'image',

            screenBackgroundColorValues: {
                '#000000': '#000000',
                '#C8C8C8': '#C8C8C8',
                '#FFFFFF': '#FFFFFF',
            },
            screenBackgroundColor: '#000000',

            screenBackgroundImage: {
                image: null
            },
            screenBackgroundBlur: false,

            bannerTypeValues: {
                image: 'Картинка',
                text: 'Текст'
            },

            tableScale: 0,
            ownContent: false,
            ownContentTypeValues: {
                image_url: 'Ссылка на картинку',
                video_url: 'Ссылка на видео',
                image: 'Загрузить картинку',
            },
            ownContentType: 'image_url',
            ownContentImageUrl: '',
            ownContentVideoUrl: '',
            ownContentImage: {
                image: null
            },

            banners: [],

            draggedElement: null,
            draggedTarget: null,

            blackListElements: new Set(),
            blackListBanners: new Set(),

            positionsTableMap: {
                positions: [...rangeList(21).map(_ => null)],
                additional: [...rangeList(3).map(_ => null)],
            },
            positionsCardsMap: {
                positions: [...rangeList(21).map(_ => null)],
                additional: [...rangeList(3).map(_ => null)],
            },

            bannerColorValues: [
                {
                    bgColor: '#222222',
                    textColor: '#FFFFFF'
                },
                {
                    bgColor: '#713353',
                    textColor: '#FFFFFF'
                },
                {
                    bgColor: '#634646',
                    textColor: '#FFFFFF'
                },
                {
                    bgColor: '#3D593B',
                    textColor: '#FFFFFF'
                },
                {
                    bgColor: '#555E7C',
                    textColor: '#FFFFFF'
                },
                {
                    bgColor: '#8F8F8F',
                    textColor: '#FFFFFF'
                },
                {
                    bgColor: '#FFC6AE',
                    textColor: '#222222'
                },
                {
                    bgColor: '#FDDF7D',
                    textColor: '#222222'
                },
                {
                    bgColor: '#ffffff',
                    textColor: '#222222'
                }
            ],

            bannerText: '',

            addBannerOpen: false,
            newBanner: {
                metaType: 'banner',
                type: 'image',
                text: '',
                background_color: '#222222',
                text_color: '#ffffff',
                color_index: 0,
                image: {},
                size: 1,
            },

            bannerModalType: 'new',
            bannerEditable: null,

            selectPosition: null,
        }

        this.createCranesItemsUnion = (owner) => {
            let results = {};
            owner.menu_items.forEach((menuItem) => {
                owner.cranes.forEach((crane) => {
                    if (crane.menu_item_id === menuItem.id) {
                        if (results[crane.menu_item_id])
                            results[crane.menu_item_id].push(crane.id);
                        else results[crane.menu_item_id] = [crane.id];
                    }
                });
            });
            return results;
        };

        this.createCranesItemsNumbers = (owner) => {
            let results = {};
            owner.cranes.forEach((crane) => {
                if (results[crane.id]) results[crane.id].push(crane.number);
                else results[crane.id] = [crane.number];
            });
            return results;
        };

        this.createMenuItemsIds = (owner) => {
            let results = {};
            owner.menu_items.forEach((menu_item) => {
                results[menu_item.id] = menu_item;
            });
            return results;
        };

        this.fileBackgroundPreviewHandler = (b64str) => {
            let item = this.state.store;
            if (item.background_image) {
                item.background_image.image = b64str;
            } else {
                item.background_image = {
                    image: b64str,
                };
            }
            this.setState({store: item});
        };

        this.onUniversalEdit = (value, obj, key, objName) => {
            obj = JSON.parse(JSON.stringify(obj));
            obj[key] = value;
            this.setState({[objName]: obj});
        };

        this.updateOwnership = (callback) => {
            API.profileOwnership(Storage.get('selectedStoreId')).then((data) => {
                data.menu_items = data.menu_items.map((menu_item) => {
                    menu_item.metaType = 'menuitem'
                    return menu_item
                })

                let menuItemsIds = {}
                data.menu_items.forEach(menuItem => {
                    menuItemsIds[menuItem.id] = menuItem
                })

                data.banners = data.banners.map(item => {
                    item.metaType = 'banner'
                    return item
                })

                let bannersIds = {}
                data.banners.forEach(banner => {
                    bannersIds[banner.id] = banner
                })

                let screens = this.state.screens.slice(0, 1)

                let loadedScreens = data.positions.screens;

                let blackListElements = new Set()
                let blackListBanners = new Set()

                let bannersIndexes = {}

                loadedScreens = loadedScreens.map(screen => {
                    screen.positions = screen.positions.map(pos => {
                        if (pos) {
                            if (pos.type === 'banner') {
                                let value = Object.assign({}, bannersIds[pos.id])
                                blackListBanners.add(pos.id)

                                if (bannersIndexes[value.id]) {
                                    bannersIndexes[value.id] += 1
                                } else {
                                    bannersIndexes[value.id] = 1
                                }

                                value._metaIndex = bannersIndexes[value.id] - 1

                                return value
                            }
                            blackListElements.add(pos.id)
                            return menuItemsIds[pos.id]
                        }
                        return null
                    })
                    screen.additional = screen.additional.map(pos => {
                        if (pos) {
                            if (pos.type === 'banner') {
                                let value = Object.assign({}, bannersIds[pos.id])
                                blackListBanners.add(pos.id)

                                if (bannersIndexes[value.id]) {
                                    bannersIndexes[value.id] += 1
                                } else {
                                    bannersIndexes[value.id] = 1
                                }

                                value._metaIndex = bannersIndexes[value.id] - 1

                                return value
                            }
                            blackListElements.add(pos.id)
                            return menuItemsIds[pos.id]
                        }
                        return null
                    })
                    return screen
                })

                loadedScreens.sort((a, b) => a.number - b.number)

                this.setState(
                    {
                        owner: data,
                        cranesItemsUnion: this.createCranesItemsUnion(data),
                        cranesItemsNumbers: this.createCranesItemsNumbers(data),
                        menuItemsIds: this.createMenuItemsIds(data),
                        screensPosition: data.screens,

                        screens: screens.concat(loadedScreens),
                        blackListElements: blackListElements,
                        blackListBanners: blackListBanners,

                        banners: data.banners
                    },
                    callback
                );
            });
        };

        this.updateBanners = () => {
            API.listBanners({storeId: this.state.store.id})
                .then(data => {
                    console.log(data)

                    this.setState({
                        banners: data.banners.map(item => {
                            item.metaType = 'banner'
                            return item
                        })
                    })
                })
        }

        this.updateShop = () => {
            API.profileStore(Storage.get('selectedStoreId')).then((data) => {
                Storage.set('store', data.store);
                this.setState({
                    store: data.store,
                    waitLabel: Boolean(data.store === null),
                });
                this.updateOwnership();
            });
        };

        this.handleDragStart = (event, element, initState) => {
            if (element.metaType === undefined) element.metaType = 'menuitem'
            element._initState = initState === true
            this.setState({draggedElement: element, draggedTarget: event.target})
        }

        this.handleDragOver = (e) => {
            e.preventDefault();
        }

        this.handleDragEnter = (e, positionIndex, tab) => {
            if (this.state.draggedElement.metaType === 'banner') {
                if (tab === 'additional') {
                    e.target.style.borderColor = '#F95724'
                } else if (this.state.displayType === 'cards') {
                    e.target.style.borderColor = '#F95724'
                } else if (this.state.draggedElement.size + positionIndex + 1 > 18) {
                    e.target.style.borderColor = '#F95724'
                } else e.target.style.borderColor = '#01ADFF'
            } else {
                e.target.style.borderColor = '#01ADFF'
            }
        }

        this.handleDragLeave = (e, positionIndex, tab) => {
            // this.setState({selectPosition: null})
            e.target.style = {}
        }

        this.handleDrop = (e, positionIndex, tab) => {
            e.target.style = {}

            let draggedElement = this.state.draggedElement

            let blackListElements = new Set(Array.from(this.state.blackListElements))
            let blackListBanners = new Set(Array.from(this.state.blackListBanners))

            if (draggedElement) {
                if (draggedElement.metaType === 'banner' && draggedElement.size + positionIndex + 1 > 18) return
                if (draggedElement.metaType === 'banner' && tab === 'additional') return;
                if (draggedElement.metaType === 'banner' && this.state.displayType === 'cards') return;

                let mapElement;
                if (this.state.displayType === 'table') {
                    mapElement = this.state.positionsTableMap
                } else {
                    mapElement = this.state.positionsCardsMap
                }

                let tabElement = mapElement[tab]
                let lastValue = tabElement[positionIndex]

                mapElement.positions = mapElement.positions.map(value => {
                    if (value && value.metaType === 'menuitem' && draggedElement.metaType === 'menuitem' && value.id === draggedElement.id) {
                        return lastValue
                    } else if (value && value.metaType === 'banner' && draggedElement.metaType === 'banner' && value.id === draggedElement.id) {
                        if (value.size > 1) return null
                        return lastValue
                    }
                    return value
                })
                if (this.state.displayType === 'table') {
                    mapElement.additional = mapElement.additional.map(value => {
                        if (value && value.metaType === 'menuitem' && draggedElement.metaType === 'menuitem' && value.id === draggedElement.id) {
                            return lastValue
                        } else if (value && value.metaType === 'banner' && draggedElement.metaType === 'banner' && value.id === draggedElement.id) {
                            if (value.size > 1) return null
                            return lastValue
                        }
                        return value
                    })
                }

                let deleteBanner = null
                if (lastValue && lastValue.metaType === 'banner' && lastValue.size > 1) deleteBanner = lastValue

                if (deleteBanner) {
                    mapElement.positions = mapElement.positions.map(value => {
                        if (value && value.metaType === 'banner' && value.id === deleteBanner.id) {
                            return null
                        }
                        return value
                    })
                }

                if (lastValue) {
                    if (lastValue.metaType === 'menuitem' && draggedElement._initState)
                        blackListElements.delete(lastValue.id)
                    if (lastValue.metaType === 'banner' && (lastValue.size > 1 || draggedElement._initState))
                        blackListBanners.delete(lastValue.id)
                }

                tabElement = mapElement[tab]
                let forDeleteMenuItems = [];
                let forDeleteBanners = [];

                if (draggedElement.metaType === 'menuitem') {
                    tabElement[positionIndex] = draggedElement

                } else if (draggedElement.metaType === 'banner' && draggedElement.size === 1) {
                    tabElement[positionIndex] = draggedElement
                } else if (draggedElement.metaType === 'banner' && draggedElement.size > 1) {
                    [...rangeList(draggedElement.size)].forEach(index => {
                        draggedElement = Object.assign({}, draggedElement)
                        draggedElement._metaIndex = index

                        let forDelete = tabElement[positionIndex + index]
                        if (forDelete && forDelete.metaType === 'menuitem') forDeleteMenuItems.push(forDelete)
                        if (forDelete && forDelete.metaType === 'banner') forDeleteBanners.push(forDelete)

                        tabElement[positionIndex + index] = draggedElement
                    })

                    forDeleteMenuItems.map(value => blackListElements.delete(value.id))
                    forDeleteBanners.map(value => blackListBanners.delete(value.id))
                }
                mapElement[tab] = tabElement

                forDeleteMenuItems.concat(forDeleteBanners).forEach(forDelete => {
                    mapElement.positions = mapElement.positions.map(value => {
                        if (value && value.metaType === 'menuitem' && forDelete.metaType === 'menuitem' && value.id === forDelete.id) {
                            return lastValue
                        } else if (value && value.metaType === 'banner' && forDelete.metaType === 'banner' && value.id === forDelete.id) {
                            if (value.size > 1) return null
                            return lastValue
                        }
                        return value
                    })
                })

                if (draggedElement.metaType === 'menuitem') {
                    blackListElements = new Set(Array.from(blackListElements).concat([draggedElement.id]))
                } else if (draggedElement.metaType === 'banner') {
                    blackListBanners = new Set(Array.from(blackListBanners).concat([draggedElement.id]))
                }

                this.setState({
                    blackListElements: blackListElements,
                    blackListBanners: blackListBanners,
                })

                if (this.state.displayType === 'table') {
                    this.setState({positionsTableMap: mapElement})
                } else {
                    this.setState({positionsCardsMap: mapElement})
                }
            }
        };

        this.handleDelete = (positionIndex, tab) => {
            let mapElement;
            if (this.state.displayType === 'table') {
                mapElement = this.state.positionsTableMap
            } else {
                mapElement = this.state.positionsCardsMap
            }

            let tabElement = mapElement[tab]
            let lastValue = positionIndex !== undefined ? tabElement[positionIndex] : this.state.draggedElement

            if (lastValue.metaType === 'menuitem') {
                let blackListElements = new Set(Array.from(this.state.blackListElements))
                blackListElements.delete(lastValue.id)
                this.setState({blackListElements: blackListElements})
            } else if (lastValue.metaType === 'banner') {
                let blackListBanners = new Set(Array.from(this.state.blackListBanners))
                blackListBanners.delete(lastValue.id)
                this.setState({blackListBanners: blackListBanners})
            }

            mapElement.positions = mapElement.positions.map(value => {
                if (value && value.metaType === 'menuitem' && value.id === lastValue.id) {
                    return null
                } else if (value && value.metaType === 'banner' && value.id === lastValue.id) {
                    return null
                }
                return value
            })
            if (this.state.displayType === 'table') {
                mapElement.additional = mapElement.additional.map(value => {
                    if (value && value.metaType === 'menuitem' && value.id === lastValue.id) {
                        return null
                    } else if (value && value.metaType === 'banner' && value.id === lastValue.id) {
                        return null
                    }
                    return value
                })
            }

            if (this.state.displayType === 'table') {
                this.setState({positionsTableMap: mapElement})
            } else {
                this.setState({positionsCardsMap: mapElement})
            }
        }

        this.setAddBannerOpen = (status, type, editable) => {
            this.setState({
                addBannerOpen: status,
                bannerModalType: type,
                bannerEditable: editable,
                newBanner: type === 'edit' ? editable : {
                    metaType: 'banner',
                    type: 'image',
                    text: '',
                    background_color: '#222222',
                    text_color: '#ffffff',
                    color_index: 0,
                    image: {},
                    size: 1,
                },
            })
        }

        this.onUniversalInput = (e, obj, key, objName) => {
            obj[key] = e.target.value;
            this.setState({[objName]: obj});
        };

        this.onUniversalValueInput = (value, obj, key, objName) => {
            obj[key] = value;
            this.setState({[objName]: obj});
        };

        this.saveBanner = () => {
            if (this.state.bannerModalType === 'new') {
                let newBanner = this.state.newBanner;

                newBanner.store_id = this.state.store.id
                newBanner.image = newBanner.image.image

                API.addBanner(newBanner)
                    .then(data => {
                        console.log(data)

                        this.setState({
                            // banners: this.state.banners.concat([newBanner]),
                            addBannerOpen: false,
                            newBanner: {
                                metaType: 'banner',
                                type: 'image',
                                text: '',
                                background_color: '#222222',
                                text_color: '#ffffff',
                                color_index: 0,
                                image: {},
                                size: 1,
                            },
                        }, this.updateBanners)
                    })
            } else if (this.state.bannerModalType === 'edit') {
                let newBanner = this.state.newBanner;

                newBanner.store_id = this.state.store.id
                newBanner.image = newBanner.image ? newBanner.image.image : null

                API.editBanner(newBanner)
                    .then(data => {
                        console.log(data)

                        this.setState({
                            // banners: this.state.banners.concat([newBanner]),
                            addBannerOpen: false,
                            newBanner: {
                                metaType: 'banner',
                                type: 'image',
                                text: '',
                                background_color: '#222222',
                                text_color: '#ffffff',
                                color_index: 0,
                                image: {},
                                size: 1,
                            },
                        }, this.updateBanners)
                    })
            }
        }

        this.editBanner = (banner) => {
            this.setAddBannerOpen(true, 'edit', banner)
        }

        this.deleteBanner = (forDelete) => {
            API.deleteBanner({store_id: this.state.store.id, banner_id: forDelete.id})
                .then(data => {
                    this.updateBanners()
                })
        }

        this.selectScreen = (screenNumber) => {
            this.insertScreen(screenNumber)
        }

        this.autoFill = () => {
            let sortField = this.state.autofillSort

            let descCompare = (a, b) => {
                if (a[sortField] > b[sortField]) {
                    return -1;
                } else if (a[sortField] < b[sortField]) {
                    return 1;
                }
                return 0;
            }

            let ascCompare = (a, b) => {
                if (a[sortField] > b[sortField]) {
                    return 1;
                } else if (a[sortField] < b[sortField]) {
                    return -1;
                }
                return 0;
            }

            let menuItems = this.state.owner.menu_items;

            const cranes = this.state.owner.cranes.filter(
                (crane) => crane.menu_item_id && crane.menu_item_id !== 'disable'
            );

            menuItems = menuItems.map(menuItem => {
                const crane =
                    cranes.find((item) => item.menu_item_id === menuItem.id) ??
                    null;

                if (sortField === 'crane' && crane === null) return null

                menuItem.crane = crane ? crane.number : null
                return menuItem
            }).filter(item => item)

            menuItems.sort(this.state.autofillOrder === 'asc' ? ascCompare : descCompare)

            let menuItemIndex = 0;

            let blackList = new Set()

            let screens = this.state.screens;
            screens = screens.map((screen, index) => {
                if (index !== 0 && !screen.own_content) {
                    if (screen.display_type === 'table') {
                        screen.positions = screen.positions.slice(0, 17).map((_, index) => {
                            if (menuItems.length > menuItemIndex) {
                                let value = menuItems[menuItemIndex]
                                menuItemIndex += 1
                                blackList.add(value.id)
                                return value
                            }
                            return null
                        })
                        screen.additional = screen.additional.map((_, index) => {
                            if (menuItems.length > menuItemIndex) {
                                let value = menuItems[menuItemIndex]
                                menuItemIndex += 1
                                blackList.add(value.id)
                                return value
                            }
                            return null
                        })
                    } else {
                        screen.positions = screen.positions.map((_, index) => {
                            if (menuItems.length > menuItemIndex) {
                                let value = menuItems[menuItemIndex]
                                menuItemIndex += 1
                                blackList.add(value.id)
                                return value
                            }
                            return null
                        })
                    }
                } else if (index !== 0 && screen.own_content) {
                    screen.positions = screen.positions.slice(0, 17).map((_, index) => {
                        return null
                    })
                    screen.additional = screen.additional.map((_, index) => {
                        return null
                    })
                }
                return screen
            })

            this.setState({
                screens: screens,
                blackListElements: blackList,
                blackListBanners: new Set(),
            })
        }

        this.autoClear = () => {
            let screens = this.state.screens;
            screens = screens.map((screen, index) => {
                if (index !== 0 && !screen.own_content) {
                    if (screen.display_type === 'table') {
                        screen.positions = screen.positions.slice(0, 17).map((_, index) => {
                            return null
                        })
                        screen.additional = screen.additional.map((_, index) => {
                            return null
                        })
                    } else {
                        screen.positions = screen.positions.map((_, index) => {
                            return null
                        })
                    }
                }
                return screen
            })

            this.setState({
                screens: screens,
                blackListElements: new Set(),
                blackListBanners: new Set(),
            })
        }

        this.publicToAny = () => {
            let screens = this.state.screens.map((screen, index) => {
                screen.display_type = this.state.displayType
                screen.vertical_alignment = this.state.verticalAlignment
                screen.dynamic_cell_size = this.state.cellSize === 'enabled'
                screen.background_type = this.state.screenBackground
                screen.background_color = this.state.screenBackgroundColor
                screen.table_scale = this.state.tableScale
                screen.own_content = this.state.ownContent
                screen.own_content_type = this.state.ownContentType
                screen.own_content_image_url = this.state.ownContentImageUrl
                screen.own_content_video_url = this.state.ownContentVideoUrl
                screen.own_content_image = this.state.ownContentImage
                screen.background_image = this.state.screenBackgroundImage
                return screen
            })

            this.setState({
                screens: screens,
                blackListElements: new Set(),
                blackListBanners: new Set(),
            })
        }

        this.insertScreen = (newScreenNumber, callback) => {
            let currentScreen = this.state.screens[this.state.screenSelected]
            let nextScreen = this.state.screens[newScreenNumber]

            if (currentScreen.type === 'general') {
                let screens = this.state.screens;
                currentScreen.display_type = this.state.displayType
                currentScreen.vertical_alignment = this.state.verticalAlignment
                currentScreen.dynamic_cell_size = this.state.cellSize === 'enabled'
                currentScreen.background_type = this.state.screenBackground
                currentScreen.background_color = this.state.screenBackgroundColor
                currentScreen.table_scale = this.state.tableScale
                currentScreen.own_content = this.state.ownContent
                currentScreen.own_content_type = this.state.ownContentType
                currentScreen.own_content_image_url = this.state.ownContentImageUrl
                currentScreen.own_content_video_url = this.state.ownContentVideoUrl
                currentScreen.own_content_image = this.state.ownContentImage
                currentScreen.background_image = this.state.screenBackgroundImage
                currentScreen.background_blur = this.state.screenBackgroundBlur

                screens[this.state.screenSelected] = currentScreen

                let nextMapElement = {positions: nextScreen.positions, additional: nextScreen.additional}
                let extra = {
                    positionsTableMap: nextMapElement,
                    positionsCardsMap: nextMapElement
                }

                this.setState({
                    screenSelected: newScreenNumber,
                    screens: screens,

                    displayType: nextScreen.display_type,
                    verticalAlignment: nextScreen.vertical_alignment,
                    cellSize: nextScreen.dynamic_cell_size ? 'enabled' : 'disabled',
                    screenBackground: nextScreen.background_type,
                    screenBackgroundColor: nextScreen.background_color,
                    screenBackgroundImage: nextScreen.background_image,
                    tableScale: nextScreen.table_scale,
                    ownContent: nextScreen.own_content,
                    ownContentType: nextScreen.own_content_type,
                    ownContentImageUrl: nextScreen.own_content_image_url,
                    ownContentVideoUrl: nextScreen.own_content_video_url,
                    ownContentImage: nextScreen.own_content_image,
                    screenBackgroundBlur: nextScreen.background_blur,
                    ...extra
                }, callback)
            } else if (nextScreen.type === 'general') {
                let screens = this.state.screens
                currentScreen.display_type = this.state.displayType
                currentScreen.vertical_alignment = this.state.verticalAlignment
                currentScreen.dynamic_cell_size = this.state.cellSize === 'enabled'
                currentScreen.background_type = this.state.screenBackground
                currentScreen.background_color = this.state.screenBackgroundColor
                currentScreen.table_scale = this.state.tableScale
                currentScreen.own_content = this.state.ownContent
                currentScreen.own_content_type = this.state.ownContentType
                currentScreen.own_content_image_url = this.state.ownContentImageUrl
                currentScreen.own_content_video_url = this.state.ownContentVideoUrl
                currentScreen.own_content_image = this.state.ownContentImage
                currentScreen.background_image = this.state.screenBackgroundImage
                currentScreen.background_blur = this.state.screenBackgroundBlur

                let mapElement;
                if (this.state.displayType === 'table') mapElement = this.state.positionsTableMap;
                else mapElement = this.state.positionsCardsMap;
                currentScreen.positions = mapElement.positions
                currentScreen.additional = mapElement.additional

                screens[this.state.screenSelected] = currentScreen

                this.setState({
                    screenSelected: newScreenNumber,
                    screens: screens,

                    displayType: nextScreen.display_type,
                    verticalAlignment: nextScreen.vertical_alignment,
                    cellSize: nextScreen.dynamic_cell_size ? 'enabled' : 'disabled',
                    screenBackground: nextScreen.background_type,
                    tableScale: nextScreen.table_scale,
                    ownContent: nextScreen.own_content,
                    screenBackgroundColor: nextScreen.background_color,
                    screenBackgroundImage: nextScreen.background_image,
                    ownContentType: nextScreen.own_content_type,
                    ownContentImageUrl: nextScreen.own_content_image_url,
                    ownContentVideoUrl: nextScreen.own_content_video_url,
                    ownContentImage: nextScreen.own_content_image,
                    screenBackgroundBlur: nextScreen.background_blur,
                }, callback)
            } else {
                let screens = this.state.screens
                currentScreen.display_type = this.state.displayType
                currentScreen.vertical_alignment = this.state.verticalAlignment
                currentScreen.dynamic_cell_size = this.state.cellSize === 'enabled'
                currentScreen.background_type = this.state.screenBackground
                currentScreen.background_color = this.state.screenBackgroundColor
                currentScreen.table_scale = this.state.tableScale
                currentScreen.own_content = this.state.ownContent
                currentScreen.own_content_type = this.state.ownContentType
                currentScreen.own_content_image_url = this.state.ownContentImageUrl
                currentScreen.own_content_video_url = this.state.ownContentVideoUrl
                currentScreen.own_content_image = this.state.ownContentImage
                currentScreen.background_image = this.state.screenBackgroundImage
                currentScreen.background_blur = this.state.screenBackgroundBlur

                let mapElement;
                if (this.state.displayType === 'table') mapElement = this.state.positionsTableMap;
                else mapElement = this.state.positionsCardsMap;
                currentScreen.positions = mapElement.positions
                currentScreen.additional = mapElement.additional

                screens[this.state.screenSelected] = currentScreen

                let nextMapElement = {positions: nextScreen.positions, additional: nextScreen.additional}
                let extra = {
                    positionsTableMap: nextMapElement,
                    positionsCardsMap: nextMapElement
                }

                this.setState({
                    screenSelected: newScreenNumber,
                    screens: screens,

                    displayType: nextScreen.display_type,
                    verticalAlignment: nextScreen.vertical_alignment,
                    cellSize: nextScreen.dynamic_cell_size ? 'enabled' : 'disabled',
                    screenBackground: nextScreen.background_type,
                    tableScale: nextScreen.table_scale,
                    ownContent: nextScreen.own_content,
                    screenBackgroundColor: nextScreen.background_color,
                    screenBackgroundImage: nextScreen.background_image,
                    ownContentType: nextScreen.own_content_type,
                    ownContentImageUrl: nextScreen.own_content_image_url,
                    ownContentVideoUrl: nextScreen.own_content_video_url,
                    ownContentImage: nextScreen.own_content_image,
                    screenBackgroundBlur: nextScreen.background_blur,
                    ...extra
                }, callback)
            }
        }

        this.editorSave = () => {
            this.insertScreen(this.state.screenSelected, () => {
                API.editorSave({storeId: this.state.store.id, screens: this.state.screens.slice(1)})
                    .then(data => {

                    })
            })
        }

        this.renderNavigation = this.renderNavigation.bind(this);
        this.renderGeneral = this.renderGeneral.bind(this);
        this.renderScreen = this.renderScreen.bind(this);
    }

    componentDidMount() {
        this.updateShop();
    }

    renderGeneral() {
        return <div className="editor-context-screen-body-general">
            <div className="editor-context-screen-body-general-header">
                Автозаполнение позиций
            </div>
            <div className="editor-context-screen-body-general-select-list">
                <div className="editor-context-screen-body-general-select-item">
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Сортировка</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={this.state.autofillSort}
                            label="Сортировка"
                            onChange={
                                (e, value) =>
                                    this.setState({autofillSort: e.target.value})
                            }
                        >
                            <MenuItem value='beer_color'>Цвету пива</MenuItem>
                            <MenuItem value='crane'>Номеру крана</MenuItem>
                            <MenuItem value='alcohol_degrees'>Алкоголю</MenuItem>
                            <MenuItem value='alcohol_density'>Плотности</MenuItem>
                            <MenuItem value='price'>Цене</MenuItem>
                            <MenuItem value='beer_sort'>Сорту</MenuItem>
                            <MenuItem value='brewery'>Пивоварне</MenuItem>
                            <MenuItem value='filtered'>Фильтрации</MenuItem>
                            <MenuItem value='name'>Названию</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div className="editor-context-screen-body-general-select-item">
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Порядок</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={this.state.autofillOrder}
                            label="Порядок"
                            onChange={
                                (e, value) =>
                                    this.setState({autofillOrder: e.target.value})
                            }
                        >
                            <MenuItem value='desc'>По убыванию</MenuItem>
                            <MenuItem value='asc'>По возрастанию</MenuItem>
                        </Select>
                    </FormControl>
                </div>
            </div>
            <div className="editor-context-screen-body-general-button" onClick={this.autoFill}>
                Заполнить
            </div>
            <div className="editor-context-screen-body-general-label">
                Также вы можете <span onClick={this.autoClear}>очистить все позиции</span>
            </div>
        </div>
    }

    renderScreen(selectedScreen) {
        if (this.state.ownContent) {
            return <div className="editor-context-screen-body-content">
                <div className="editor-context-screen-body-content-header">
                    Загрузите медиа или укажите ссылку
                </div>
                <div className="editor-context-screen-settings-multi-radio">
                    <MultiRadioButton
                        title='Выберите, что именно выводить'
                        values={this.state.ownContentTypeValues}
                        selected={this.state.ownContentType}
                        selectValue={(value) =>
                            this.setState({ownContentType: value})
                        }
                    />
                </div>
                {
                    this.state.ownContentType === 'video_url' ?
                        <div className="editor-context-screen-body-content-input">
                            <TextField
                                margin='dense'
                                id='video-url'
                                name='video-url'
                                label='Ссылка на видео'
                                type='text'
                                fullWidth
                                variant='outlined'
                                value={this.state.ownContentVideoUrl}
                                onInput={
                                    (event) =>
                                        this.setState({ownContentVideoUrl: event.target.value})
                                }
                            />
                        </div> : null
                }
                {
                    this.state.ownContentType === 'image_url' ?
                        <div className="editor-context-screen-body-content-input">
                            <TextField
                                margin='dense'
                                id='image-url'
                                name='image-url'
                                label='Ссылка на картинку'
                                type='text'
                                fullWidth
                                variant='outlined'
                                value={this.state.ownContentImageUrl}
                                onInput={
                                    (event) =>
                                        this.setState({ownContentImageUrl: event.target.value})
                                }
                            />
                        </div> : null
                }
                {
                    this.state.ownContentType === 'image' ?
                        <div className="editor-context-screen-body-content-image-uploader">
                            <ImageUploader
                                title='Изображение'
                                description='.png, .jpeg, .gif до 10мб'
                                size='small'
                                imageBase64={this.state.ownContentImage ? this.state.ownContentImage.image : null}
                                url={this.state.ownContentImage ? this.state.ownContentImage.url : null}
                                uploadHandler={b64str => {
                                    let ownContentImage = {image: b64str};
                                    this.setState({ownContentImage: ownContentImage});
                                }}/>
                        </div> : null
                }
            </div>
        }

        if (this.state.displayType === 'table') {
            return <div className="editor-context-screen-body-screen">
                <div className="editor-context-screen-body-screen-table">
                    <div className="editor-context-screen-body-screen-table-cells">
                        {
                            this.state.positionsTableMap.positions.slice(0, 17).map((value, index) => {
                                if (value) {
                                    if (value.metaType === 'menuitem') return <div
                                        key={index}
                                        className="editor-context-screen-body-screen-table-cell-menuitem"
                                        onDragOver={this.handleDragOver}
                                        onDragEnter={(e) => this.handleDragEnter(e, index, 'positions')}
                                        onDragLeave={(e) => this.handleDragLeave(e, index, 'positions')}
                                        onDrop={(e) => this.handleDrop(e, index, 'positions')}
                                        onDragStart={(event) => this.handleDragStart(event, value)}
                                        draggable
                                    >
                                        <div className="editor-context-screen-body-screen-table-cell-menuitem-avatar">
                                            {value.image ? (
                                                <img alt={value.name} src={SERVER_URL + value.image.url}/>
                                            ) : null}
                                        </div>
                                        <div className="editor-context-screen-body-screen-table-cell-menuitem-name">
                                            {value.name}
                                        </div>
                                        <div
                                            className="editor-context-screen-body-screen-table-cell-menuitem-delete"
                                            onClick={() => this.handleDelete(index, 'positions')}
                                        >
                                            <TrashIcon/>
                                        </div>
                                    </div>
                                    else if (value.metaType === 'banner') {
                                        const isStart = value._metaIndex === 0;
                                        const isMiddle = value._metaIndex > 0 && value._metaIndex + 1 !== value.size;
                                        const isEnd = value._metaIndex + 1 === value.size;

                                        let bannerClass = ['editor-context-screen-body-screen-table-cell-banner']

                                        if (isStart) bannerClass.push('editor-context-screen-body-screen-table-cell-banner-start')
                                        else if (isMiddle) bannerClass.push('editor-context-screen-body-screen-table-cell-banner-middle')
                                        else if (isEnd) bannerClass.push('editor-context-screen-body-screen-table-cell-banner-end')

                                        if (value.size === 1) {
                                            return <div
                                                key={index}
                                                className="editor-context-screen-body-screen-table-cell-banner"
                                                onDragOver={this.handleDragOver}
                                                onDragEnter={(e) => this.handleDragEnter(e, index, 'positions')}
                                                onDragLeave={(e) => this.handleDragLeave(e, index, 'positions')}
                                                onDrop={(e) => this.handleDrop(e, index, 'positions')}
                                                onDragStart={(event) => this.handleDragStart(event, value)}
                                                draggable
                                            >
                                                {value.type === 'image' ?
                                                    <div
                                                        className="editor-context-screen-body-screen-table-cell-banner-avatar">
                                                        {value.image ? (
                                                            <img alt={value.name} src={
                                                                value.image.image ?
                                                                    value.image.image :
                                                                    SERVER_URL + value.image.url
                                                            }/>
                                                        ) : null}
                                                    </div> : null}
                                                <div
                                                    className="editor-context-screen-body-screen-table-cell-banner-name-container">
                                                    <div
                                                        className="editor-context-screen-body-screen-table-cell-banner-name">
                                                        {value.type === 'image' ? 'Баннер-картинка' : value.text}
                                                    </div>
                                                    {value.type === 'text' && value.size === 1 ?
                                                        <div
                                                            className="editor-context-screen-body-screen-table-cell-banner-label">
                                                            Текстовый баннер
                                                        </div> : null}
                                                </div>
                                                <div
                                                    className="editor-context-screen-body-screen-table-cell-banner-delete"
                                                    onClick={() => this.handleDelete(index, 'positions')}
                                                >
                                                    <TrashIcon/>
                                                </div>
                                            </div>
                                        } else if (value.size === 2) {
                                            return <div
                                                key={index}
                                                className={bannerClass.join(' ')}
                                                onDragOver={this.handleDragOver}
                                                onDragEnter={(e) => this.handleDragEnter(e, index, 'positions')}
                                                onDragLeave={(e) => this.handleDragLeave(e, index, 'positions')}
                                                onDrop={(e) => this.handleDrop(e, index, 'positions')}
                                                onDragStart={(event) => this.handleDragStart(event, value)}
                                                draggable
                                            >
                                                {value.type === 'image' ?
                                                    <div
                                                        className="editor-context-screen-body-screen-table-cell-banner-avatar">
                                                        {value.image ? (
                                                            <img alt={value.name} src={
                                                                value.image.image ?
                                                                    value.image.image :
                                                                    SERVER_URL + value.image.url
                                                            }/>
                                                        ) : null}
                                                    </div> : null}
                                                <div
                                                    className="editor-context-screen-body-screen-table-cell-banner-name-container">
                                                    <div
                                                        className="editor-context-screen-body-screen-table-cell-banner-name">
                                                        {value.type === 'image' ? 'Баннер-картинка' : value.text}
                                                    </div>
                                                    {value.type === 'text' && value.size === 1 ?
                                                        <div
                                                            className="editor-context-screen-body-screen-table-cell-banner-label">
                                                            Текстовый баннер
                                                        </div> : null}
                                                    {value.type === 'text' && (value.size >= 2) && isEnd ?
                                                        <div
                                                            className="editor-context-screen-body-screen-table-cell-banner-label">
                                                            Текстовый баннер, {value.size} ячейки
                                                        </div> : null}
                                                    {value.type === 'image' && (value.size >= 2) && isEnd ?
                                                        <div
                                                            className="editor-context-screen-body-screen-table-cell-banner-label">
                                                            {value.size} ячейки
                                                        </div> : null}
                                                </div>
                                                <div
                                                    className="editor-context-screen-body-screen-table-cell-banner-delete"
                                                    onClick={() => this.handleDelete(index, 'positions')}
                                                >
                                                    <TrashIcon/>
                                                </div>
                                            </div>
                                        } else {
                                            return <div
                                                key={index}
                                                className={bannerClass.join(' ')}
                                                onDragOver={this.handleDragOver}
                                                onDragEnter={(e) => this.handleDragEnter(e, index, 'positions')}
                                                onDragLeave={(e) => this.handleDragLeave(e, index, 'positions')}
                                                onDrop={(e) => this.handleDrop(e, index, 'positions')}
                                                onDragStart={(event) => this.handleDragStart(event, value)}
                                                draggable
                                            >
                                                {value.type === 'image' ?
                                                    <div
                                                        className="editor-context-screen-body-screen-table-cell-banner-avatar">
                                                        {value.image ? (
                                                            <img alt={value.name} src={
                                                                value.image.image ?
                                                                    value.image.image :
                                                                    SERVER_URL + value.image.url
                                                            }/>
                                                        ) : null}
                                                    </div> : null}
                                                <div
                                                    className="editor-context-screen-body-screen-table-cell-banner-name-container">
                                                    <div
                                                        className="editor-context-screen-body-screen-table-cell-banner-name">
                                                        {value.type === 'image' ? 'Баннер-картинка' : value.text}
                                                    </div>
                                                    {value.type === 'text' && value.size === 1 ?
                                                        <div
                                                            className="editor-context-screen-body-screen-table-cell-banner-label">
                                                            Текстовый баннер
                                                        </div> : null}
                                                    {value.type === 'text' && (value.size >= 2) && isEnd ?
                                                        <div
                                                            className="editor-context-screen-body-screen-table-cell-banner-label">
                                                            Текстовый баннер, {value.size} ячейки
                                                        </div> : null}
                                                    {value.type === 'image' && (value.size >= 2) && isEnd ?
                                                        <div
                                                            className="editor-context-screen-body-screen-table-cell-banner-label">
                                                            {value.size} ячейки
                                                        </div> : null}
                                                </div>
                                                <div
                                                    className="editor-context-screen-body-screen-table-cell-banner-delete"
                                                    onClick={() => this.handleDelete(index, 'positions')}
                                                >
                                                    <TrashIcon/>
                                                </div>
                                            </div>
                                        }
                                    }
                                }

                                return <div
                                    key={index}
                                    className="editor-context-screen-body-screen-table-cell"
                                    onDragOver={this.handleDragOver}
                                    onDragEnter={(e) => this.handleDragEnter(e, index, 'positions')}
                                    onDragLeave={(e) => this.handleDragLeave(e, index, 'positions')}
                                    onDrop={(e) => this.handleDrop(e, index, 'positions')}
                                >
                                    Позиция {index + 1}
                                </div>
                            })
                        }
                    </div>
                    <div className="editor-context-screen-body-screen-table-big-cells">
                        {
                            this.state.positionsTableMap.additional.map((value, index) => {
                                if (value) {
                                    return <div
                                        key={index}
                                        className="editor-context-screen-body-screen-table-big-cell-menuitem"
                                        onDragOver={this.handleDragOver}
                                        onDragEnter={(e) => this.handleDragEnter(e, index, 'additional')}
                                        onDragLeave={(e) => this.handleDragLeave(e, index, 'additional')}
                                        onDrop={(e) => this.handleDrop(e, index, 'additional')}
                                        onDragStart={(event) => this.handleDragStart(event, value)}
                                        draggable
                                    >
                                        <div className="editor-context-screen-body-screen-table-big-cell-menuitem-top">
                                            <div
                                                className="editor-context-screen-body-screen-table-big-cell-menuitem-avatar">
                                                {value.image ? (
                                                    <img alt={value.name} src={SERVER_URL + value.image.url}/>
                                                ) : null}
                                            </div>
                                            <div
                                                className="editor-context-screen-body-screen-table-big-cell-menuitem-delete"
                                                onClick={() => this.handleDelete(index, 'additional')}
                                            >
                                                <TrashIcon/>
                                            </div>
                                        </div>
                                        <div className="editor-context-screen-body-screen-table-big-cell-menuitem-name">
                                            {value.name}
                                        </div>
                                    </div>
                                }

                                return <div
                                    key={index}
                                    className="editor-context-screen-body-screen-table-big-cell"
                                    onDragOver={this.handleDragOver}
                                    onDragEnter={(e) => this.handleDragEnter(e, index, 'additional')}
                                    onDragLeave={(e) => this.handleDragLeave(e, index, 'additional')}
                                    onDrop={(e) => this.handleDrop(e, index, 'additional')}
                                >
                                    Позиция {17 + index + 1}
                                </div>
                            })
                        }
                    </div>
                </div>
            </div>
        }
        return <div className="editor-context-screen-body-screen">
            <div className="editor-context-screen-body-screen-cards">
                <div className="editor-context-screen-body-screen-cards-cells">
                    {
                        this.state.positionsCardsMap.positions.map((value, index) => {
                            if (value) {
                                return <div
                                    key={index}
                                    className="editor-context-screen-body-screen-cards-cell-menuitem"
                                    onDragOver={this.handleDragOver}
                                    onDragEnter={(e) => this.handleDragEnter(e, index, 'positions')}
                                    onDragLeave={(e) => this.handleDragLeave(e, index, 'positions')}
                                    onDrop={(e) => this.handleDrop(e, index, 'positions')}
                                    onDragStart={(event) => this.handleDragStart(event, value)}
                                    draggable
                                >
                                    <div className="editor-context-screen-body-screen-cards-cell-menuitem-top">
                                        <div
                                            className="editor-context-screen-body-screen-cards-cell-menuitem-avatar">
                                            {value.image ? (
                                                <img alt={value.name} src={SERVER_URL + value.image.url}/>
                                            ) : null}
                                        </div>
                                        <div
                                            className="editor-context-screen-body-screen-cards-cell-menuitem-delete"
                                            onClick={() => this.handleDelete(index, 'positions')}
                                        >
                                            <TrashIcon/>
                                        </div>
                                    </div>
                                    <div className="editor-context-screen-body-screen-cards-cell-menuitem-name">
                                        {value.name}
                                    </div>
                                </div>
                            }

                            return <div
                                key={index}
                                className="editor-context-screen-body-screen-cards-cell"
                                onDragOver={this.handleDragOver}
                                onDragEnter={(e) => this.handleDragEnter(e, index, 'positions')}
                                onDragLeave={(e) => this.handleDragLeave(e, index, 'positions')}
                                onDrop={(e) => this.handleDrop(e, index, 'positions')}
                            >
                                п{index + 1}
                            </div>
                        })
                    }
                </div>
            </div>
        </div>
    }

    renderNavigation() {
        let channelUrl = (windowNumber) => {
            return `/store/window/${windowNumber}/channel/${this.state.owner.store.web_key}/`;
        };

        let filteredBanners = this.state.banners
            .filter(banner => !this.state.blackListBanners.has(banner.id))

        let filteredMenuItems = this.state.owner.menu_items
            .filter(menuItem => !this.state.blackListElements.has(menuItem.id))

        return <div className="editor-navigation-container">
            <div className="editor-navigation-content">
                <div className="editor-navigation-logo-container">
                    <a className="HeaderMenu-logo" href="/cabinet">
                        <LogoIcon/>
                    </a>
                    <div className="editor-navigation-logo-buttons">
                        <div className='customer-dashboard-content-shop-right-windows'>
                            <ButtonComponent
                                className='customer-header-button-component'
                                href={channelUrl(1)}
                                label='1'
                            />
                            <ButtonComponent
                                className='customer-header-button-component'
                                href={channelUrl(2)}
                                label='2'
                            />
                            <ButtonComponent
                                className='customer-header-button-component'
                                href={channelUrl(3)}
                                label='3'
                            />
                        </div>
                    </div>
                </div>
                <div className="editor-navigation-items-container">
                    <div className="banner-list-container">
                        <div className="banner-list-header-container">
                            <div className="banner-list-header">Баннеры</div>
                            <div className="banner-list-header-add" onClick={() => this.setAddBannerOpen(true, 'new')}>
                                <BannerAddIcon/>
                            </div>
                        </div>
                        {
                            filteredBanners.length === 0 ? <div className="banner-list-header-description">
                                Вы можете разместить в одной или нескольких ячейках картинку или текстовый баннер
                            </div> : <div className="banner-list"
                                          onDragOver={this.handleDragOver}
                                          onDrop={() => this.handleDelete()}
                            >
                                {
                                    filteredBanners.map(banner => {
                                        return <div
                                            key={banner.id}
                                            className="banner-list-item"
                                            draggable
                                            onDragStart={(event) => this.handleDragStart(event, banner, true)}
                                        >
                                            <div className="banner-list-item-content-edit"
                                                 onClick={() => this.editBanner(banner)}>
                                                <EditPencilIcon/>
                                            </div>
                                            {
                                                banner.type === 'image' ?
                                                    <div className="banner-list-item-avatar">
                                                        {banner.image ? (
                                                            <img alt={banner.name} src={
                                                                banner.image.image ?
                                                                    banner.image.image :
                                                                    SERVER_URL + banner.image.url
                                                            }/>
                                                        ) : null}
                                                    </div> : null
                                            }

                                            <div className="banner-list-item-content">
                                                <div className="banner-list-item-content-header">
                                                    {banner.type === 'image' ? 'Картинка' : banner.text}
                                                </div>
                                                {
                                                    banner.type === 'image' ? null :
                                                        <div className="banner-list-item-content-text">
                                                            Текстовый баннер
                                                        </div>
                                                }
                                            </div>
                                            <div
                                                className="banner-list-item-content-delete"
                                                onClick={() => this.deleteBanner(banner)}
                                            >
                                                <TrashIcon/>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        }
                    </div>
                    <div className="menuitem-list-container">
                        <div className="menuitem-list-header-container">
                            <div className="menuitem-list-header">Пиво</div>
                        </div>
                        <div className="menuitem-list-search-container">
                            <TextField
                                margin='dense'
                                id='menuitem-search'
                                name='menuitem-search'
                                label='Поиск'
                                type='text'
                                fullWidth
                                variant='outlined'
                            />
                        </div>
                        <div
                            className="menuitem-list"
                            onDragOver={this.handleDragOver}
                            onDrop={() => this.handleDelete()}
                        >
                            {
                                filteredMenuItems.map(menuItem => {
                                    let craneIds = this.state.cranesItemsUnion[menuItem.id];
                                    const cranes = craneIds ? Array.from(craneIds) : [];

                                    let numbers = cranes.map((craneId) => this.state.cranesItemsNumbers[craneId]);

                                    let craneLabel = ''
                                    if (numbers.length === 1) {
                                        craneLabel = 'Кран ' + numbers.map((craneId) => craneId).join(', ')
                                    }

                                    return <div
                                        key={menuItem.id}
                                        className="menuitem-list-item"
                                        draggable
                                        onDragStart={(event) => this.handleDragStart(event, menuItem, true)}
                                    >
                                        <div className="menuitem-list-item-avatar">
                                            {menuItem.image && menuItem.image.url ? (
                                                <img alt={menuItem.name} src={SERVER_URL + menuItem.image.url.split('?')[0]}/>
                                            ) : null}
                                        </div>
                                        <div className="menuitem-list-item-content">
                                            <div className="menuitem-list-item-content-header">
                                                {menuItem.name}
                                            </div>
                                            <div className="menuitem-list-item-content-text">
                                                {craneLabel}
                                            </div>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="editor-navigation-help-container">
                <div className="editor-navigation-help-label">Помощь</div>
                <div className="editor-navigation-help-icon">
                    <TelegramBlackIcon/>
                </div>
            </div>
        </div>
    }

    renderContext(selectedScreen) {
        const sliderMarks = [
            {
                value: 0,
                label: '0%',
            },
            {
                value: 15,
                label: '15%',
            },
        ];

        function valuetext(value) {
            return `${value}`;
        }

        let bodyClass = ["editor-context-screen-body-container"];
        if (this.state.displayType === 'cards') bodyClass.push('editor-context-screen-body-container-fix')

        return <div className="editor-context-container">
            <div className="editor-context-header-container">
                <div className="editor-context-header-left">
                    <div className="editor-context-header-title">Конструктор отображения</div>
                    <div className="editor-context-header-label">Количество экранов устанавливается при вашем
                        подключении
                    </div>
                </div>
                <div className="editor-context-header-buttons">
                    <div className="editor-context-header-button editor-context-header-button-blue"
                         onClick={this.editorSave}>
                        Сохранить
                    </div>
                    <div className="editor-context-header-button" onClick={() => window.location.href = '/'}>
                        Отмена
                    </div>
                </div>
            </div>
            <div className="editor-context-screens-container">
                {
                    this.state.screens.map((screen, index) => {
                        let classScreen = ['editor-context-screens-screen']
                        if (screen.number === this.state.screenSelected) {
                            classScreen.push('editor-context-screens-screen-selected')
                        }

                        return <div key={index} className={classScreen.join(' ')}
                                    onClick={() => this.selectScreen(screen.number)}>
                            {
                                screen.type === 'general' ? <>
                                    <div className="editor-context-screens-screen-name">{screen.name}</div>
                                    <div className="editor-context-screens-screen-label">{screen.label}</div>
                                </> : <>
                                    <div className="editor-context-screens-screen-name">
                                        Экран {screen.number}
                                    </div>
                                    <div className="editor-context-screens-screen-label">
                                        {screen.own_content ? 'Свой контент' : 'Меню'}
                                    </div>
                                </>
                            }
                        </div>
                    })
                }
            </div>
            <div className='editor-context-screen-body-layout'>
                <div className={bodyClass.join(' ')}>
                    {selectedScreen.type === 'general' ? this.renderGeneral() : this.renderScreen(selectedScreen)}
                </div>
                <div className="editor-context-screen-settings-container">
                    {
                        selectedScreen.type === 'general' ?
                            <div className="editor-context-screen-settings-return-container">
                                <div className="editor-context-screen-settings-return-label">
                                    Настройки некоторых экранов отличаются от общих
                                </div>
                                <div className="editor-context-screen-settings-return-action"
                                     onClick={this.publicToAny}>
                                    Применить общие настройки ко всем экранам
                                </div>
                            </div> : null
                    }
                    {
                        selectedScreen.type !== 'general' ?
                            <div className="editor-context-screen-settings-own-content-container">
                                <div className="editor-context-screen-settings-own-content-label">
                                    Свой контент вместо меню
                                </div>
                                <div className="editor-context-screen-settings-own-content-switch">
                                    <Switch aria-label="own-content"
                                            checked={this.state.ownContent}
                                            onChange={(e, checked) => this.setState({ownContent: checked})}
                                    />
                                </div>
                            </div> : null
                    }
                    {
                        this.state.ownContent ? null : <>
                            <div className="editor-context-screen-settings-multi-radio">
                                <MultiRadioButton
                                    title='Вид отображения'
                                    values={this.state.displayTypeValues}
                                    selected={this.state.displayType}
                                    selectValue={(value) => {
                                        if (value === 'table' && this.state.displayType === 'cards') {
                                            let positions = this.state.positionsCardsMap.positions.slice(0, 17)
                                            let additional = this.state.positionsCardsMap.positions.slice(17, 20)

                                            let forDelete = this.state.positionsCardsMap.positions[20]

                                            if (forDelete) {
                                                if (forDelete.metaType === 'menuitem') {
                                                    let blackListElements = new Set(Array.from(this.state.blackListElements))
                                                    blackListElements.delete(forDelete.id)
                                                    this.setState({blackListElements: blackListElements})
                                                } else if (forDelete.metaType === 'banner') {
                                                    let blackListBanners = new Set(Array.from(this.state.blackListBanners))
                                                    blackListBanners.delete(forDelete.id)
                                                    this.setState({blackListBanners: blackListBanners})
                                                }
                                            }

                                            this.setState({
                                                positionsTableMap: {
                                                    positions: positions,
                                                    additional: additional
                                                },
                                                positionsCardsMap: {
                                                    positions: [...rangeList(21).map(_ => null)],
                                                    additional: [...rangeList(3).map(_ => null)],
                                                }
                                            })

                                        } else if (value === 'cards' && this.state.displayType === 'table') {
                                            let positions = this.state.positionsTableMap.positions.slice(0, 17)
                                            let additional = this.state.positionsTableMap.additional.concat(null)

                                            let bannersForDelete = []

                                            this.setState({
                                                positionsTableMap: {
                                                    positions: [...rangeList(21).map(_ => null)],
                                                    additional: [...rangeList(3).map(_ => null)]
                                                },
                                                positionsCardsMap: {
                                                    positions: positions.concat(additional).map(value => {
                                                        if (value && value.metaType === 'banner') {
                                                            bannersForDelete.push(value.id)
                                                            return null
                                                        }
                                                        return value
                                                    }),
                                                    additional: [...rangeList(3).map(_ => null)],
                                                }
                                            })

                                            let blackListBanners = new Set(Array.from(this.state.blackListBanners))
                                            bannersForDelete.forEach(valueId => blackListBanners.delete(valueId))
                                            this.setState({blackListBanners: blackListBanners})

                                        }
                                        this.setState({displayType: value})
                                    }}
                                />
                            </div>
                            {
                                this.state.displayType === 'table' ? <>
                                    <div className="editor-context-screen-settings-multi-radio">
                                        <MultiRadioButton
                                            title='Вертикальное выравнивание позиций, центрирование'
                                            values={this.state.verticalAlignmentValues}
                                            selected={this.state.verticalAlignment}
                                            selectValue={(value) =>
                                                this.setState({verticalAlignment: value})
                                            }
                                        />
                                    </div>
                                    <div className="editor-context-screen-settings-multi-radio">
                                        <MultiRadioButton
                                            title='Динамический размер ячеек'
                                            values={this.state.cellSizeValues}
                                            selected={this.state.cellSize}
                                            selectValue={(value) =>
                                                this.setState({cellSize: value})
                                            }
                                            description='В зависимости от количества заполненных позиций ячейки будут растягиваться или сужаться'
                                        />
                                    </div>
                                </> : null
                            }
                            <div className="editor-context-screen-settings-multi-radio">
                                <MultiRadioButton
                                    title='Фон'
                                    values={this.state.screenBackgroundValues}
                                    selected={this.state.screenBackground}
                                    selectValue={(value) =>
                                        this.setState({screenBackground: value})
                                    }
                                />
                            </div>
                            {this.state.screenBackground === 'image' ? (
                                <div className='editor-context-screen-settings-background'>
                                    <InputLabel>Фоновое изображение</InputLabel>
                                    <ImageUploader
                                        title='Изображение'
                                        description='.png, .jpeg, .gif до 10мб'
                                        size='large'
                                        imageBase64={this.state.screenBackgroundImage ? this.state.screenBackgroundImage.image : null}
                                        url={this.state.screenBackgroundImage ? this.state.screenBackgroundImage.url : null}
                                        uploadHandler={b64str => {
                                            let screenBackgroundImage = {
                                                image: b64str,
                                            };
                                            this.setState({screenBackgroundImage: screenBackgroundImage});
                                        }}
                                    />
                                    <div className='modal-edit-product-switch-container'>
                                        Размыть изображение
                                        <div className='modal-edit-product-horizontal-cell-card-switch'>
                                            <Switch
                                                checked={this.state.screenBackgroundBlur}
                                                onChange={
                                                    (e, checked) =>
                                                        this.setState({screenBackgroundBlur: checked})
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            ) : null}

                            {this.state.screenBackground === 'color' ? (
                                <div className='editor-context-screen-settings-background-colors'>
                                    <MultiRadioButton
                                        title='Цвет'
                                        values={this.state.screenBackgroundColorValues}
                                        selected={this.state.screenBackgroundColor}
                                        colors={true}
                                        selectValue={(value) => this.setState({screenBackgroundColor: value})}
                                    />
                                </div>
                            ) : null}

                            <div className="editor-context-screen-settings-slider-radio">
                                <div className="editor-context-screen-settings-slider-label">
                                    Настройка смещения, если на телевизоре обрезаются края
                                </div>
                                <Slider
                                    aria-label="offset"
                                    defaultValue={0}
                                    getAriaValueText={valuetext}
                                    step={0.1}
                                    max={15}
                                    valueLabelDisplay="auto"
                                    marks={sliderMarks}
                                    value={this.state.tableScale}
                                    onChange={(e, value) => this.setState({tableScale: value})}
                                />
                            </div>
                        </>
                    }
                </div>
            </div>
        </div>
    }

    render() {
        const sliderMarks = [
            {
                value: 1,
                label: '1',
            },
            {
                value: 17,
                label: '17',
            },
        ];

        function valuetext(value) {
            return `${value}`;
        }

        let selectedScreen = this.state.screens[this.state.screenSelected]

        return <>
            <div className="editor-root">
                {this.renderNavigation()}
                {this.renderContext(selectedScreen)}
                <Dialog
                    scroll={'body'}
                    classes={{root: 'editor-context-banner-add-modal'}}
                    open={this.state.addBannerOpen}
                    onClose={() => this.setAddBannerOpen(false)}
                    maxWidth={'sm'}
                    fullWidth={true}
                    PaperProps={{
                        component: 'form',
                        onSubmit: (event) => {
                            event.preventDefault();
                            const formData = new FormData(event.currentTarget);
                            const formJson = Object.fromEntries(formData.entries());
                            // API.storeItemAdd(
                            //     formJson.name,
                            //     formJson.description,
                            //     formJson.category,
                            //     formJson.price,
                            //     this.state.imageBase64,
                            //     this.state.store.id
                            // ).then((data) => {
                            //     this.updateOwnership();
                            // });
                            this.setAddBannerOpen(false);
                        },
                    }}
                >
                    <div className='editor-context-banner-add-modal-title-container'>
                        <div className='editor-context-banner-add-modal-title'>
                            Баннер
                        </div>
                        <IconButton onClick={() => this.setAddBannerOpen(false)}>
                            <CloseRoundedIcon/>
                        </IconButton>
                    </div>
                    <div className="editor-context-banner-add-modal-multi-radio">
                        <MultiRadioButton
                            values={this.state.bannerTypeValues}
                            selected={this.state.newBanner.type}
                            selectValue={(value) =>
                                this.onUniversalValueInput(value, this.state.newBanner, 'type', 'newBanner')
                            }
                        />
                    </div>

                    {
                        this.state.newBanner.type === 'image' ?
                            <>
                                <div className="editor-context-banner-add-modal-image-uploader">
                                    <ImageUploader
                                        title='Изображение'
                                        description={<>
                                            .png, .jpeg, .gif до 10мб
                                            <br/>
                                            <br/>
                                            Рекомендуемый размер
                                            <br/>
                                            4K
                                            <br/>
                                            3231x{this.state.newBanner.size * 108} при наличии боковых ячеек
                                            <br/>
                                            3795x{this.state.newBanner.size * 108}
                                            <br/>
                                            <br/>
                                            FullHD
                                            <br/>
                                            1597x{this.state.newBanner.size * 56} при наличии боковых ячеек
                                            <br/>
                                            1875x{this.state.newBanner.size * 56}
                                        </>}
                                        size='large'
                                        imageBase64={this.state.newBanner.image ? this.state.newBanner.image.image : null}
                                        url={this.state.newBanner.image ? this.state.newBanner.image.url : null}
                                        uploadHandler={b64str => {
                                            let item = this.state.newBanner;
                                            item.image = {
                                                image: b64str,
                                            };
                                            this.setState({newBanner: item});
                                        }}/>
                                </div>
                            </> :
                            <>
                                <div className='editor-context-banner-add-modal-inputs-container'>
                                    <TextField
                                        margin='dense'
                                        id='text'
                                        name='text'
                                        label='Текст'
                                        type='text'
                                        fullWidth
                                        variant='outlined'
                                        value={safetyInput(this.state.newBanner.text)}
                                        onInput={(e) =>
                                            // this.setState({bannerText: e.target.value})
                                            this.onUniversalInput(e, this.state.newBanner, 'text', 'newBanner')
                                        }
                                    />
                                </div>
                                <div className='editor-context-banner-add-modal-inputs-container'>
                                    <div className='editor-context-screen-settings-background-colors'>
                                        <MultiRadioColorsButton
                                            title='Цвет'
                                            values={this.state.bannerColorValues}
                                            selectedIndex={this.state.newBanner.color_index}
                                            selectValue={(index) => {
                                                const value = this.state.bannerColorValues[index]
                                                let obj = this.state.newBanner;
                                                obj.background_color = value.bgColor;
                                                obj.text_color = value.textColor;
                                                obj.color_index = index;
                                                this.setState({newBanner: obj})
                                            }}
                                        />
                                    </div>
                                </div>
                            </>
                    }

                    <div className="editor-context-banner-add-modal-slider-radio">
                        <div className="editor-context-screen-settings-slider-label">
                            Количество занимаемых ячеек
                        </div>
                        <Slider
                            aria-label="offset"
                            defaultValue={1}
                            getAriaValueText={valuetext}
                            step={1}
                            min={1}
                            max={17}
                            valueLabelDisplay="auto"
                            marks={sliderMarks}
                            value={this.state.newBanner.size}
                            onChange={(event, value) => {
                                this.onUniversalInput(event, this.state.newBanner, 'size', 'newBanner')
                            }}
                        />
                    </div>

                    <div className='modal-edit-product-bottom-buttons'>
                        <Button
                            classes={{root: 'modal-edit-product-bottom-button-save'}}
                            component='label'
                            role={undefined}
                            variant='contained'
                            tabIndex={-1}
                            onClick={() => this.saveBanner()}>
                            Сохранить баннер
                        </Button>
                        <Button
                            classes={{root: 'modal-edit-product-bottom-button-cancel'}}
                            component='label'
                            role={undefined}
                            variant='contained'
                            tabIndex={-1}
                            onClick={() => this.setAddBannerOpen(false)}>
                            Отменить
                        </Button>
                    </div>
                </Dialog>
            </div>
            <div className="editor-mobile-banner">
                <NoMobileIcon/>
                <div className="editor-mobile-banner-title">Конструктор недоступен на мобильной версии</div>
                <a className="editor-mobile-banner-button" href="/">Вернуться</a>
            </div>
        </>
    }
}

export default EditorComponent;
