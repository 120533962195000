import React from "react";
import {Alert, Button, Snackbar, TextField} from "@mui/material";
import '../style/auth.css';
import {ReactComponent as LogoIcon} from "../images/logo.svg";


class Auth extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            login: '',
            password: '',
            snackbarOpen: false
        }

        this.handleSnackbarClose = () => {
            this.setState({snackbarOpen: false})
        }

        this.handleSnackbarOpen = () => {
            this.setState({snackbarOpen: true})
        }

        this.authorize = () => {
            const pathname = window.location.pathname;

            this.props.authorize(this.state.login, this.state.password, (status) => {
                if (!status) {
                    this.handleSnackbarOpen()
                    document.getElementById('login').focus()
                } else {
                    if (pathname === '/login' || pathname === '/login/') {
                        setTimeout(() => {
                            window.location.href = '/cabinet'
                        }, 1000)
                    }
                }
            })
        }
    }

    render() {
        return <div className="dashboard-auth-container">
            <div className="dashboard-auth-container-entry">
                <h1 className="dashboard-auth-logo">
                    <a className="HeaderMenu-logo" href="/">
                        <LogoIcon/>
                    </a>
                </h1>
                <h2 className="dashboard-auth-label">
                    Авторизация
                </h2>
                <div className="dashboard-auth-field">
                    <TextField id="login" label="Email" variant="outlined"
                               onChange={(e) => this.setState({login: e.target.value})}
                               onKeyUp={(e) => {
                                   if (e.key === "Enter") document.getElementById('password').focus()
                               }}
                    />
                </div>
                <div className="dashboard-auth-field">
                    <TextField id="password" label="Пароль" variant="outlined" type="password"
                               onChange={(e) => this.setState({password: e.target.value})}
                               onKeyUp={(e) => {
                                   if (e.key === "Enter") {
                                       let buttonAuth = document.getElementById('button-auth')
                                       buttonAuth.focus()
                                       buttonAuth.click()
                                   }
                               }}/>
                </div>
                <div className="dashboard-auth-button">
                    <Button id="button-auth" variant="outlined" size="medium"
                            onClick={this.authorize}>
                        Войти
                    </Button>
                </div>
            </div>
            <Snackbar open={this.state.snackbarOpen} autoHideDuration={3000} onClose={this.handleSnackbarClose}
                      anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}>
                <Alert onClose={this.handleSnackbarClose} severity="error" sx={{width: '100%'}}>
                    Неверный email или пароль
                </Alert>
            </Snackbar>
        </div>
    }
}

export default Auth;
