import { DEBUG, } from './constants'
import process from "process";

const isProd = process.env.NODE_ENV === 'production'
const isTest = process.env.NODE_ENV === 'test'
const isDev = !(isProd || isTest)


const dev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development'

const LOGGING_ENABLED = isDev
const LOG_LEVEL = DEBUG

const SERVER_URL = dev ? 'http://127.0.0.1:5000' : '' // set this if your API server is different from the frontend server

const SITE_NAME = 'PubHub'
const COPYRIGHT = 'PubHub'

const HIGHLIGHT_LANGUAGES = [
    'javascript',
    'json',
    'python',
    'scss',
    'yaml',
]

export {
    LOGGING_ENABLED,
    LOG_LEVEL,
    SERVER_URL,
    SITE_NAME,
    COPYRIGHT,
    HIGHLIGHT_LANGUAGES,
}

// rm -rf ./static/css/*
// rm -rf ./static/js/*
// rm -rf ./static/media/*
// rm -rf ./templates/*
// rm -rf ./static/css/* && rm -rf ./static/js/* && rm -rf ./static/media/* && rm -rf ./templates/*