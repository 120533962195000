import React from 'react';
import cl from './pubhub-promo.module.css';
import {ReactComponent as CustomPubHubLogoForTableView} from '../../../images/customPubHubLogoForTableView.svg';

function PubhubPromo() {
  return (
    <div className={cl.promoWrapper}>
      <CustomPubHubLogoForTableView />
      <span className={cl.pubhubUrl}>pubhub.su</span>
    </div>
  );
}

export default PubhubPromo;
