import React from 'react';
import cl from './landing-header.module.css';
import {ReactComponent as CustomIconLogo} from '../../../../../images/logo.svg';
import {ReactComponent as CustomIconTelegram} from '../../../../../images/telegram.svg';
import Utils from '../../../../utils';

function LandingHeader() {
  const isMobile = Utils.isDeviceMobile();

  return (
    <div className={cl.headerWrapper}>
      <div className={cl.headerComposition}>
        <a href='/'>
          <CustomIconLogo />
        </a>

        {!isMobile && (
          <nav className={cl.navBar}>
            <span className={cl.announce}>Пишите, звоните</span>

            <a href='tel:+79505071316' className={cl.contact}>
              8&nbsp;950&nbsp;507&nbsp;13&nbsp;16
            </a>

            <div className={cl.telegram}>
              <CustomIconTelegram />

              <a
                target='_blank'
                href='https://t.me/pubhubsu'
                className={cl.channel}
              >
                @pubhubsu
              </a>
            </div>
          </nav>
        )}

        <a className={cl.login} href='/login'>
          Войти
        </a>
      </div>
    </div>
  );
}

export default LandingHeader;
