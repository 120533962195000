import React from "react";
import "./account-page.component.css";
import RequestInfoComponent from "./components/requisites-info/requisites-info.component";
import SubscribtionInfoComponent from "./components/subscribtion-info/subscribtion-info.component";
import AccountInfoComponent from "./components/account-info/account-info.component";
import ChangePasswordComponent from "./components/change-password/change-password.component";

function AccountPageComponent() {
  return (
    <div className="main-container">
      <div className="page-name">Аккаунт и подписка</div>
      <div className="account-cards-wrapper">
        <div className="cards">
          <RequestInfoComponent />
          <SubscribtionInfoComponent />
        </div>
        <div className="cards">
          <AccountInfoComponent />
          <ChangePasswordComponent />
        </div>
      </div>
    </div>
  );
}

export default AccountPageComponent;
