import React from 'react';
import cl from './fixed-actions.module.css';

function FixedActions({openDialog}) {
  return (
    <div className={cl.actionsWrapper}>
      <button onClick={() => openDialog()} className={cl.actionWant}>
        Хочу попробовать
      </button>
      {/*<a href='/price' className={cl.actionOnPrice}>*/}
      {/*  Цены*/}
      {/*</a>*/}
    </div>
  );
}

export default FixedActions;
