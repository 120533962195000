import React from "react";
import '../../../style/menuSelectElement.css'
import {ReactComponent as ArrowSelectIcon} from "../../../images/arrowSelect.svg";


function MenuContentElement({title, label}) {
    return <div className="MenuSelectElement-left-content">
        <div className="MenuSelectElement-name">
            {title}
        </div>
        <div className="MenuSelectElement-address">
            {label}
        </div>
    </div>
}

function taskHandler(isMenuOpen, setMenuOpen, iconHandler) {

    function wrapper(event) {
        if (event.target &&
            String(event.target).indexOf('SVGPathElement') < 0 &&
            String(event.target).indexOf('SVGSVGElement') < 0 &&
            !Array.from(event.target.classList).includes('MenuSelectElement-right-content')
        ) {
            setMenuOpen(!isMenuOpen)
        } else {
            if (iconHandler) iconHandler()
        }
    }

    return wrapper
}

function MenuSelectElement({
                               isMenuOpen,
                               mainTitle,
                               mainLabel,
                               setMenuOpen,
                               icon,
                               iconHandler,
                               simpleElementsText,
                               elements,
                               elementHandler,
                               extraElement,
                               width,
                               arrowSide
                           }) {
    let isArrowSideLeft = typeof arrowSide !== 'undefined' ? arrowSide === 'left' : true

    let rootClassList = ['MenuSelectElement-left']
    if (isMenuOpen) rootClassList.push('MenuSelectElement-left-opened')
    rootClassList = rootClassList.join(' ')

    let arrowClassList = [isArrowSideLeft ? 'MenuSelectElement-left-arrow' : 'MenuSelectElement-right-arrow']
    if (isMenuOpen) arrowClassList.push(isArrowSideLeft ? 'MenuSelectElement-left-arrow-opened' : 'MenuSelectElement-right-arrow-opened')
    arrowClassList = arrowClassList.join(' ')

    let skeletonClassList = ['MenuSelectElement-left-skeleton']
    if (isMenuOpen) skeletonClassList.push('MenuSelectElement-left-skeleton-opened')
    skeletonClassList = skeletonClassList.join(' ')

    let clickHandler = taskHandler(isMenuOpen, setMenuOpen, iconHandler)

    let style = {}
    if (width) style['--width'] = `${width}px`

    let iconElement = icon ? <div className="MenuSelectElement-right-content">{icon}</div> : null
    let arrowElement = <div className={arrowClassList}><ArrowSelectIcon/></div>

    return <div className="MenuSelectElement-container" style={style}>
        <div className={rootClassList} onClick={clickHandler}>
            <div className="MenuSelectElement-left-union">
                {
                    isArrowSideLeft ? arrowElement : null
                }
                <MenuContentElement title={mainTitle} label={mainLabel}/>
                {iconElement}
                {
                    !isArrowSideLeft ? arrowElement : null
                }
            </div>
            {
                isMenuOpen ? <>
                    {
                        simpleElementsText ? <div className="MenuSelectElement-left-simple-title">
                            {simpleElementsText}
                        </div> : null
                    }
                    {
                        elements.map((element, index) => {
                            return <div key={index} onClick={() => elementHandler(element)}
                                        className="MenuSelectElement-left-content MenuSelectElement-left-other-shop">
                                <div className="MenuSelectElement-name">
                                    {element.title}
                                </div>
                                <div className="MenuSelectElement-address">
                                    {element.label}
                                </div>
                            </div>
                        })
                    }
                    {
                        extraElement ? <>{extraElement}</> : null
                    }
                </> : null
            }
        </div>
        <div className={skeletonClassList}/>
    </div>
}

export default MenuSelectElement;


