import React from "react";
import useNoSleep from "use-no-sleep";


function NoSleepEngine() {
    useNoSleep(true)

    return <></>
}

export default NoSleepEngine;