import {post, url} from '../../../../utils/request';

export default class ConnectRequestService {
  static async onSendRequest(params) {
    console.log(params);
    const response = await post(url('/api/landing/request/', {...params}));

    return response;
  }
}
