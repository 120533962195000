import React, {useState} from "react";
import './image-uploader.component.css';
import {SERVER_URL} from "../../../config";
import Button from "@mui/material/Button";
import {styled} from "@mui/material/styles";

import {ReactComponent as ClearIcon} from "../../../images/clear.svg";
import {ReactComponent as ReplaceIcon} from "../../../images/replace.svg";
import {Fingerprint} from "../../../utils/storage";


const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});


function ImageUploader({title, description, imageBase64, url, uploadHandler, size}) {
    const [internalId, setInternalId] = useState(Fingerprint.hashFunc(String(Math.random())))

    if (['small', 'large'].indexOf(size) < 0) {
        size = 'small'
    }

    const fileInputChange = (event) => {
        const target = event.target;
        if (target.files && target.files[0]) {
            const file = target.files[0];
            const reader = new FileReader();
            reader.onload = (event) => {
                let base64String = event.target.result;
                if (uploadHandler) uploadHandler(base64String);
            };
            reader.readAsDataURL(file);
        }
    };

    let previewClass = ['ImageUploader-preview']
    if (size === 'small') previewClass.push('ImageUploader-preview-small')
    else if (size === 'large') previewClass.push('ImageUploader-preview-large')

    return <div className="ImageUploader">
        {title ? <div className="ImageUploader-title">{title}</div> : null}

        <div className={previewClass.join(' ')}>
            {
                imageBase64 || url ? (
                    <>
                        <img alt='preview' src={imageBase64 ? imageBase64 : SERVER_URL + url}/>
                        <div className="ImageUploader-preview-buttons">
                            <div className="ImageUploader-preview-button"
                                 onClick={() => {
                                     document.getElementById(`image-${internalId}`).click()
                                 }}>
                                <ReplaceIcon/>
                                Заменить
                            </div>
                            <div className="ImageUploader-preview-button"
                                 onClick={() => uploadHandler ? uploadHandler(null) : null}>
                                <ClearIcon/>
                                Очистить
                            </div>
                        </div>
                    </>
                ) : null
            }
            <Button
                component='label'
                role={undefined}
                variant='contained'
                tabIndex={-1}
                disabled={typeof uploadHandler !== 'function'}
                style={{display: imageBase64 || url ? 'none' : 'flex'}}
            >
                Загрузить изображение
                <VisuallyHiddenInput
                    id={`image-${internalId}`}
                    name='image'
                    type='file'
                    accept='image/*'
                    onChange={(event) => fileInputChange(event)}
                />
            </Button>
        </div>

        {description ? <div className="ImageUploader-description">{description}</div> : null}
    </div>
}

export default ImageUploader;