import React, {useEffect, useState} from 'react';
import classes from './subscribtion-info.module.css';
import MyCheckboxComponent from '../../../my-checkbox/my-checkbox.component';
import MyTabComponent from '../../../my-tabs/my-tab.component';
import PaymentService from './services/payment.service';

function SubscribtionInfoComponent() {
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [period, setPeriod] = useState(1);
  const [stores, setStores] = useState([]);
  const [invoiceStatus, setStatus] = useState();
  const [docsChanged, setDocChanged] = useState(false);
  const [availableSubscriptions, setAvailableSubscribtions] = useState([]);
  const [errorCancelling, setErrorCancelling] = useState(false);

  useEffect(() => {
    PaymentService.getSubscriptionInfo({
      period: period,
      stores: stores,
    }).then((res) => {
      return setSubscriptionData(res);
    });

    PaymentService.getInvoiceStatus().then((res) =>
      res.invoice ? setStatus(true) : setStatus(null)
    );

    PaymentService.getCurrentSubscriptions().then((res) =>
      setAvailableSubscribtions(
        res.stores.filter((data) => data.subscription_active !== false)
      )
    );

    setDocChanged(false);
  }, [period, stores, docsChanged]);

  const onSelectPeriod = (period) => {
    setPeriod(period);
  };

  const onSelectStore = (store) => {
    stores.includes(store)
      ? setStores([...stores.filter((item) => item !== store)])
      : setStores([...stores, store]);
  };

  function onCreateInvoice() {
    PaymentService.createInvoice({
      period: period,
      stores: stores,
    }).then(() => setStatus(true));
  }

  function onInstallPdfInvoice() {
    PaymentService.getInvoiceFile().then((res) =>
      window.open(`https://pubhub.su${res.url}`, '_blank')
    );
  }

  function onCheckPayStatus() {
    PaymentService.getInvoiceStatus().then((res) =>
      res.invoice ? setStatus(true) : setStatus(null)
    );
  }

  function onApproveOffer() {
    PaymentService.approveOffer();
    setDocChanged(true);
  }

  function onApprovePrivacy() {
    PaymentService.approvePrivacy();
    setDocChanged(true);
  }

  function onCancelBilling() {
    PaymentService.postCancelBilling()
      .then((res) => setStatus(null))
      .catch(() => setErrorCancelling(true));
  }

  return (
    <div className='card-wrapper'>
      <div className={classes.cardHeaderWrapper}>
        <span className='card-name'>Подписка</span>

        {subscriptionData !== null && subscriptionData.personal_discount ? (
          <div className={classes.personalDiscountWrapper}>
            <span className={classes.discountTypography}>
              Ваша скидка учтена
            </span>
            <span className={classes.personalDiscount}>
              {subscriptionData.personal_discount}%
            </span>
          </div>
        ) : null}
      </div>
      {subscriptionData !== null ? (
        <>
          <div className={classes.paymentInfo}>
            <div className={classes.subheader}>Итоговая стоимость</div>
            <div>
              <div className={classes.price}>
                {subscriptionData.total.price} ₽
              </div>
              {subscriptionData.total.price ===
              subscriptionData.total.without_discount ? (
                <div className={classes.priceDescription}>
                  скидок не предусмотрено
                </div>
              ) : (
                <del className={classes.totalPriceWithoutDiscount}>
                  {subscriptionData.total.without_discount} ₽
                </del>
              )}
            </div>
            <div className={classes.divider}></div>
            {subscriptionData.stores.items.map((unit) => (
              <div key={unit.id} className={classes.units}>
                <MyCheckboxComponent
                  value={subscriptionData.stores.checked.some(
                    (check) => check === unit.id
                  )}
                  unitId={unit.id}
                  select={onSelectStore}
                />
                <div>
                  <div className={classes.unitName}>
                    {unit.name} ({unit.address})
                  </div>
                  <div className={classes.pricePerUnit}>
                    <div className={classes.perMonth}>{unit.price} ₽ / мес</div>
                    {unit.price !== unit.without_discount ? (
                      <del className={classes.perMonthWithoutDiscount}>
                        {unit.without_discount} ₽ / мес
                      </del>
                    ) : null}
                  </div>
                </div>
              </div>
            ))}
            {subscriptionData.connection.paid === false ? (
              <div className={classes.units}>
                <MyCheckboxComponent value={true} disabled />
                <div>
                  <div className={classes.unitName}>Подключение сервиса</div>
                  <div className={classes.pricePerUnit}>
                    <div className={classes.perMonth}>
                      {subscriptionData.connection.price} ₽
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>

          <div className={classes.periods}>
            <div className={classes.periodsTitle}>
              Количество месяцев подписки
            </div>
            <div className={classes.periodsComposition}>
              {subscriptionData.periods.items.map((period) => (
                <MyTabComponent
                  key={period.id}
                  count={period.count}
                  discount={period.discount}
                  active={subscriptionData.periods.checked === period.id}
                  onSelect={onSelectPeriod}
                  periodId={period.id}
                />
              ))}
            </div>
          </div>
          <div>
            {invoiceStatus === null ? (
              <button
                disabled={
                  stores.length === 0 ||
                  subscriptionData.documents.privacy === false ||
                  subscriptionData.documents.offer === false
                }
                className={classes.actionSend}
                onClick={() => onCreateInvoice()}>
                Выставить счет
              </button>
            ) : (
              <div className={classes.invoiceActions}>
                <div className={classes.invoiceActive}>
                  Счёт выставлен, но пока не оплачен
                </div>

                <div className={classes.buttonsComposition}>
                  <button
                    onClick={() => onCheckPayStatus()}
                    className={classes.checkPay}>
                    Проверить оплату
                  </button>

                  <button
                    onClick={() => onInstallPdfInvoice()}
                    className={classes.getPdf}>
                    Скачать pdf
                  </button>
                </div>

                {invoiceStatus ? <strong>Ожидает оплаты</strong> : null}
              </div>
            )}
          </div>
        </>
      ) : (
        // за хуйню ниже искренне извиняюсь
        <strong>Идет згрузка...</strong>
      )}
      {availableSubscriptions.length > 0 ? (
        <div className={classes.availableSubscriptions}>
          <div className={classes.availableSubscriptionsTitle}>
            Оплаченные периоды
          </div>
          <div className={classes.currentSubscriptions}>
            {availableSubscriptions.map((periodInfo) => (
              <div
                key={periodInfo.id}
                className={classes.currentSubscriptionsWrapper}>
                <div className={classes.subscriptionStoreInfo}>
                  {periodInfo.name} ({periodInfo.address})
                </div>
                <div className={classes.subscriptionEndInfo}>
                  до{' '}
                  {new Intl.DateTimeFormat('de-DE').format(
                    new Date(periodInfo.subscription_end)
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : null}
      <div className={classes.nuancesComposition}>
        <div className={classes.nuances}>
          Для выставления счёта вам необходимо ознакомиться с документами
        </div>

        {subscriptionData ? (
          <>
            <div>
              <a
                rel='noreferrer'
                href='https://pubhub.su/documents/privacy'
                target='_blank'
                onClick={() => onApprovePrivacy()}
                className={classes.nuance}>
                <div className={classes.nuancePart}>
                  <MyCheckboxComponent
                    value={subscriptionData.documents.privacy}
                    disabled
                  />
                  <div className={classes.nuanceName}>
                    Политика конфиденциальности
                  </div>
                </div>
                <svg
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'>
                  <path d='M9 6L15 12L9 18' stroke='#C1C1C1' strokeWidth='2' />
                </svg>
              </a>
            </div>
            <div>
              <a
                rel='noreferrer'
                href='https://pubhub.su/documents/oferta'
                target='_blank'
                onClick={() => onApproveOffer()}
                className={classes.nuance}>
                <div className={classes.nuancePart}>
                  <MyCheckboxComponent
                    value={subscriptionData.documents.offer}
                    disabled
                  />
                  <div className={classes.nuanceName}>Публичная оферта</div>
                </div>
                <svg
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'>
                  <path d='M9 6L15 12L9 18' stroke='#C1C1C1' strokeWidth='2' />
                </svg>
              </a>
            </div>
          </>
        ) : null}
      </div>
      {/* доделать когда бэк будет готов */}
      {invoiceStatus !== null ? (
        <div className={classes.cancelInvoiceComposition}>
          <div className={classes.youCan}>Вы&nbsp;можете&nbsp;</div>
          <div
            onClick={() => onCancelBilling()}
            className={classes.cancelInvoice}>
            отменить выставленный счёт
          </div>
          <span className={classes.youCan}>
            ,&nbsp;не&nbsp;делайте этого, если уже оплатили
          </span>
        </div>
      ) : null}

      {errorCancelling === true ? (
        <span className={classes.errorCancelling}>
          Произошла ошибка, попробуйте позже
        </span>
      ) : null}
    </div>
  );
}

export default SubscribtionInfoComponent;
