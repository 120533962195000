import React from 'react';
import cl from './contacts.module.css';
import {ReactComponent as CustomIconTelegram} from '../../../../../../../images/telegram.svg';

function Contacts() {
  return (
    <div className={cl.contactsWrapper}>
      <h2 className={cl.header}>Контакты</h2>

      <a href='tel:+79505071316' className={cl.contact}>
        <span className={cl.tel}>8&nbsp;950&nbsp;507&nbsp;13&nbsp;16</span>
        <span className={cl.desc}>основной телефон</span>
      </a>

      <a
        href='https://wa.me/+79505071316?text=Здравствуйте,%20'
        className={cl.contact}
      >
        <span className={cl.tel}>8&nbsp;950&nbsp;507&nbsp;13&nbsp;16</span>
        <span className={cl.desc}>whatsapp, второй номер</span>
      </a>

      <a
        href='mailto:hello@pubhub.su?body=Здравствуйте, хочу подключиться!'
        className={cl.contact}
      >
        <span className={cl.tel}>hello@pubhub.su</span>
        <span className={cl.desc}>Почта</span>
      </a>

      <div className={cl.telegram}>
        <CustomIconTelegram />

        <div className={cl.chanelWrapper}>
          <div className={cl.channel}>
            <a target='_blank' href='https://t.me/pubhubsu'>
              @pubhubsu
            </a>
            <span>канал</span>
          </div>

          <div className={cl.channel}>
            <a target='_blank' href='https://t.me/pub_hub'>
              @pub_hub
            </a>
            <span>менеджер</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contacts;
