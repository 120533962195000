import React from "react";
import '../style/SupportPage.css';
import API from "../api";
import Storage from "../utils/storage";
import ButtonComponent from "./components/buttonComponent";
import HeaderMenu from "./components/headerMenu";
import MenuSelectElement from "./components/menuSelectElement";
import {Accordion, AccordionActions, AccordionDetails, AccordionSummary} from "@mui/material";
import Button from "@mui/material/Button";


function renderWaitLabel(contacts) {
    return <div className="customer-dashboard-container">
        <div className="customer-dashboard-wait-label-layer">
            <div className="customer-dashboard-wait-label-container">
                <h1 className="customer-dashboard-logo">
                    PubHub <span className="styled-label">Menus</span>
                </h1>
                <div className="customer-dashboard-wait-label-title">
                    Магазин в процессе создания
                </div>
                <div className="customer-dashboard-wait-label-text">
                    Создание вашего магазина уже находится в процессе.<br/>
                    Мы делаем все возможное, чтобы все было готово как можно скорее.<br/>
                    Спасибо за ваше терпение и поддержку.<br/>
                    Мы обязательно сообщим вам, как только все будет готово к запуску.<br/>
                </div>
                <div className="customer-dashboard-wait-label-help">
                    <div className="customer-dashboard-wait-label-help-title">
                        Контакты:
                    </div>
                    <div className="customer-dashboard-wait-label-help-field">
                        Email: <a href={`mailto:${contacts.email}`}>{contacts.email}</a>
                    </div>
                    <div className="customer-dashboard-wait-label-help-field">
                        Телефон: <a href={`tel:${contacts.phone}`}>{contacts.phone}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

function ExpandMoreIcon() {
    return null;
}

class SupportPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            store: null,
            stores: [],
            contacts: {},
            waitLabel: null,
            isMenuOpen: false,
            owner: null,

            editAll: false,
            addProductsOpen: false,
            editProductsOpen: false,
            shopSettingsOpen: false,
            tagsSettingsOpen: false,
            contentSettingsOpen: false,

            manyMenuElement: null,
            manyMenuOpen: false,
            profileMenuOpen: false,
            filtersOpen: false,
            filtersElement: null,
            sortingOpen: false,
            sortingElement: null,
            cranesOpen: false,
            craneSelected: null,

            cranesShowFull: false,

            category: null,
            categories: [],

            imageBase64: null,

            editableItem: {},
            newItem: {},
            tagsMenuItem: {},

            contentSettingsTypes: {tv: 'TV', layouts: 'Макеты'},
            contentSettingsType: 'tv',

            pasteurizedValues: {true: 'Да', false: 'Нет', null: '—'},
            pasteurized: true,

            beerColorValues: {light: 'Светлое', dark: 'Темное', sweet: 'Сладкое'},
            beerColor: 'light',

            filteredBeerValues: {true: 'Да', false: 'Нет', null: '—'},
            filteredBeer: true,

            shopBackgroundValues: {image: 'Картинка', color: 'Цвет'},
            shopBackground: 'image',

            televisions: [],
            layouts: [],

            shopBackgroundColorValues: {
                '#222222': '#222222',
                '#D0D0D0': '#D0D0D0',
                '#FFFFFF': '#FFFFFF',
                '#E95E2A': '#E95E2A',
                '#6930C3': '#6930C3',
            },
            shopBackgroundColor: '#222222',

            tagsColorValues: {
                null: 'missing',
                '#FF564E': '#FF564E',
                '#28BC00': '#28BC00',
                '#4A51F0': '#4A51F0',
                '#7E2AE9': '#7E2AE9',
            },
            tagsColor: 'missing',

            shopProductAvatarResizeValues: {
                contain: 'Contain', cover: 'Cover', fill: 'Fill',
                'scale-down': 'Scale-down', none: '—'
            },
            shopProductAvatarResize: 'contain',

            shopSortValues: {number: 'По номеру крана', item_type: 'По типу пива'},
            shopSort: 'number',

            displayType: Storage.get('InterfaceSettingsDisplayType', 'cards'),
            order: 'asc',
            orderBy: null
        }

        this.updateOwnership = (callback) => {
            API.profileOwnership(Storage.get('selectedStoreId'))
                .then(data => {
                    this.setState({owner: data}, callback)
                })
        }

        this.updateShop = () => {
            API.profileStore(Storage.get('selectedStoreId'))
                .then(data => {
                    Storage.set('store', data.store)
                    this.setState({
                        store: data.store,
                        waitLabel: Boolean(data.store === null)
                    })
                    this.updateOwnership()
                })
        }

        this.setProfileMenuOpen = (status) => this.setState({
            profileMenuOpen: status,
        })

        this.updateUser = (value, field) => {
            console.log([value, field])
        }

        this.renderInternal = this.renderInternal.bind(this);
    }

    componentDidMount() {
        API.contacts()
            .then(data => {
                this.setState({contacts: data.contacts})
            })

        API.categories()
            .then(data => {
                this.setState({categories: data.categories})
            })

        API.profileStores()
            .then(data => {
                this.setState({stores: data.stores})
            })

        this.updateShop()
    }

    renderInternal() {
        let user = Storage.get('user', {})

        return <div className="customer-account-container"
                    style={{'--avatar-resize-method': this.state.store.product_avatar_resize}}>
            <div className="customer-account-content">
                <div className="customer-account-content-container">
                    <div className="customer-account-content-column customer-support-content-column">
                        <div className="customer-account-content-account-container customer-support-content-account-container">
                            <div className="customer-account-content-account-base">
                                <div className="customer-account-content-account-title">Помощь</div>
                                <div className="customer-account-content-account-credential-container customer-support-content-account-credential-container">
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon/>}
                                            aria-controls="panel1-content"
                                            id="panel1-header"
                                        >
                                            Как мне срать
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            Да похуй мне как ты срать будешь, но лучше стоя. GG @ИКОРКА КРАСНАЯ РАСПРЕКРАСНАЯ, you just advanced to level 4! GG @ИКОРКА КРАСНАЯ РАСПРЕКРАСНАЯ, you just advanced to level 4! GG @ИКОРКА КРАСНАЯ РАСПРЕКРАСНАЯ, you just advanced to level 4!
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon/>}
                                            aria-controls="panel2-content"
                                            id="panel2-header"
                                        >
                                            Как мне срать
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            Да похуй мне как ты срать будешь, но лучше стоя. GG @ИКОРКА КРАСНАЯ РАСПРЕКРАСНАЯ, you just advanced to level 4! GG @ИКОРКА КРАСНАЯ РАСПРЕКРАСНАЯ, you just advanced to level 4! GG @ИКОРКА КРАСНАЯ РАСПРЕКРАСНАЯ, you just advanced to level 4!
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon/>}
                                            aria-controls="panel3-content"
                                            id="panel3-header"
                                        >
                                            Как мне срать
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            Да похуй мне как ты срать будешь, но лучше стоя. GG @ИКОРКА КРАСНАЯ РАСПРЕКРАСНАЯ, you just advanced to level 4! GG @ИКОРКА КРАСНАЯ РАСПРЕКРАСНАЯ, you just advanced to level 4! GG @ИКОРКА КРАСНАЯ РАСПРЕКРАСНАЯ, you just advanced to level 4!
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }

    render() {
        let user = Storage.get('user', {})

        if (this.state.waitLabel) {
            return renderWaitLabel(this.state.contacts)
        } else if (this.state.waitLabel === null) {
            return null
        }

        let buttons = [
            {
                id: 'main', title: 'Главная', label: 'Перейти на главную',
                href: '/'
            },
            {
                id: 'logout', title: 'Выйти', label: 'Выйти из аккаунта',
                href: '/logout'
            }
        ]

        let elementHandler = (button) => {
            window.location.href = button.href
        }

        return <HeaderMenu
            header={<>
                <div className="customer-dashboard-content-shop-right customer-dashboard-content-account-right">
                    <ButtonComponent className="customer-header-button-component" label="Вернуться"
                                     href="/cabinet"/>
                </div>
                <MenuSelectElement
                    isMenuOpen={this.state.profileMenuOpen}
                    mainTitle={`${user.first_name} ${user.last_name}`}
                    mainLabel={'Профиль'}
                    setMenuOpen={this.setProfileMenuOpen}
                    elements={buttons}
                    elementHandler={elementHandler}
                    width={280}
                    arrowSide="right"
                />
            </>}
            contentClass="customer-dashboard-container-main"
            content={this.renderInternal()}/>
    }
}

export default SupportPage;
